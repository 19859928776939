import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import formatNumber from 'helpers/formatNumber';
import CropIconMapping from 'components/CropIconMapping';

const CropLegend = ({ crops, cropColors, show, isExporting, style }) => {
  if (!show) return null;

  return (
    <div
      className="absolute w-200px z-2 p-2 pb-4 bg-white border-1/2 border-neutral-60"
      style={style}
    >
      <Row
        className="mb-2"
        justify="center"
        style={{
          fontSize: 10,
          lineHeight: '16px',
          fontWeight: 500
        }}
      >
        Map Legend
      </Row>
      {crops.map((crop, i) => (
        <Row
          key={crop.id}
          className={`${i > 0 ? 'mt-1' : ''} h-14px`}
          align="middle"
          gutter={2}
        >
          <Col className={isExporting ? 'p-1px pt-2' : ''}>
            <div
              className="w-14px h-14px"
              style={{ background: cropColors[crop.id] }}
            />
          </Col>
          <Col className={isExporting ? 'p-1px pt-2' : ''}>
            <CropIconMapping size={12} cropObject={crop} />
          </Col>
          <Col flex="auto" style={{ fontSize: '8px', lineHeight: '14px' }}>
            {crop.name}
          </Col>
          <Col style={{ fontSize: '8px', lineHeight: '14px' }}>
            {formatNumber(crop.area, 'ac')}
          </Col>
        </Row>
      ))}
    </div>
  );
};

CropLegend.propTypes = {
  crops: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      area: PropTypes.number.isRequired
    })
  ).isRequired,
  cropColors: PropTypes.objectOf(PropTypes.string).isRequired,
  show: PropTypes.bool,
  isExporting: PropTypes.bool,
  style: PropTypes.objectOf()
};

CropLegend.defaultProps = {
  show: true,
  isExporting: false,
  style: {}
};

export default CropLegend;
