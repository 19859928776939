import React from 'react';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import stateItems from 'components/AddressFields/StateSelect/data';
import { Input, Select } from '@agconnections/grow-ui';
import { useFormikContext } from 'formik';

const AddressFields = () => {
  const { values } = useFormikContext();
  return (
    <>
      <div className="col-span-2 my-4 mr-4 ">
        <GrowUIFormField
          value={values.addressLineOne}
          placeholder=""
          control={Input}
          type="text"
          name="addressLineOne"
          label="Address Line 1"
        />
      </div>
      <div className="col-span-2 my-4 mr-4 ">
        <GrowUIFormField
          value={values.addressLineTwo}
          placeholder=""
          control={Input}
          type="text"
          name="addressLineTwo"
          label="Address Line 2"
        />
      </div>
      <div className="col-span-2 my-4 mr-4 ">
        <GrowUIFormField
          value={values.city}
          placeholder=""
          control={Input}
          type="text"
          name="city"
          label="City"
        />
      </div>
      <div className="col-span-1 my-4 mr-4 ">
        <GrowUIFormField
          value={values.state}
          placeholder=""
          control={Select}
          items={[{ key: null, value: '\u2002' }].concat(stateItems)}
          type="text"
          name="state"
          label="State"
        />
      </div>
      <div className="col-span-1 my-4 mr-4 ">
        <GrowUIFormField
          value={values.zipCode}
          placeholder=""
          control={Input}
          type="text"
          name="zipCode"
          label="Zip Code"
        />
      </div>
    </>
  );
};

export default AddressFields;
