import React, { useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { Row, Col } from 'syngenta-digital-cropwise-react-ui-kit';
import PropTypes from 'prop-types';
import {
  getAbbreviatedUnit,
  MEASUREMENT_UNITS
} from 'helpers/unitsAbbreviation';
import { getBushelWeight } from 'helpers/getBushelWeight';
import YieldQuantityField from '../YieldSectionContainer/components/YieldQuantityField';
import calculateAverageQuantity from '../../helpers/calculateAverageQuantity';

const YieldSection = ({ selectedCropName }) => {
  const { setFieldValue, values } = useFormikContext();
  const bushelWeight = useMemo(() => getBushelWeight(selectedCropName), [
    selectedCropName
  ]);

  useEffect(() => {
    if (!selectedCropName || values.loadUnit) return;
    setFieldValue(
      'loadUnit',
      bushelWeight ? MEASUREMENT_UNITS.BUSHEL : MEASUREMENT_UNITS.POUND
    );
  }, [selectedCropName, setFieldValue, bushelWeight, values.loadUnit]);

  const isPropertySelected = useMemo(() => {
    const isSourceSelected = values.sources !== undefined;
    return (
      isSourceSelected &&
      values.sourceType === 'CROP_ZONE' &&
      values.sources?.length > 0
    );
  }, [values.sources, values.sourceType]);

  const averageQuantity = useMemo(() => {
    return calculateAverageQuantity({
      quantity: values.load,
      area: values.totalArea
    });
  }, [values.load, values.totalArea]);

  return (
    <Row gutter={[24, 24]} prefixCls="syt-antd-row">
      <Col xs={24} sm={24} md={12} lg={12} xl={8} prefixCls="syt-antd-col">
        <YieldQuantityField
          fieldName="load"
          selectedCropName={selectedCropName}
          label="Yield Quantity"
        />
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={8} prefixCls="syt-antd-col">
        {isPropertySelected && (
          <div className="flex flex-col gap-0.5">
            <p className="text-neutral-60 tracking-tighter text-sm">
              Average Quantity
            </p>
            <p className="text-base leading-normal">
              {averageQuantity} {getAbbreviatedUnit(values.loadUnit)} / ac
            </p>
          </div>
        )}
      </Col>
    </Row>
  );
};

YieldSection.propTypes = {
  selectedCropName: PropTypes.string.isRequired
};

export default YieldSection;
