import React, { useState } from 'react';
import ChevronDown from 'assets/chevron_down.svg';
import CompanyIcon from 'assets/company.svg';
import PersonIcon from 'assets/person.svg';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import {
  Button,
  Drawer,
  Select,
  Spin
} from 'syngenta-digital-cropwise-react-ui-kit';
import * as Yup from 'yup';
import usePeopleData from 'hooks/usePeopleData';
import useCompanies from 'hooks/useCompanies';
import Close from 'assets/close.svg';
import AddPerson from './components/AddPerson';
import AddCompany from './components/AddCompany';
import './index.css';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('name is required')
});

const AddNewCompanyPeopleDrawer = ({
  open,
  onClose,
  onCompanyPeopleAdded,
  title
}) => {
  const [
    companyPeopleTypeSelectOpen,
    setCompanyPeopleTypeSelectOpen
  ] = useState(false);
  const [companyPeopleType, setCompanyPeopleType] = useState('Company');

  const { createPerson, isCreatingPerson } = usePeopleData();

  const { createCompany, isCreatingCompany } = useCompanies();

  const onSubmit = (v, { resetForm }) => {
    if (companyPeopleType === 'People') {
      const personBody = {
        name: v.name,
        email: v.email,
        phone: v.phone,
        companyId: v.company,
        address: {
          line1: v.addressLineOne,
          line2: v.addressLineTwo,
          city: v.city,
          state: v.state,
          zipCode: v.zipCode,
          country: v.country
        },
        applicatorLicenseNumber: v.applicatorLicenseNumber,
        applicatorLicenseNumberExpirationDate:
          v.applicationLicenseExperiationDate,
        notes: v.notes
      };
      createPerson(personBody).then(({ id, name }) => {
        if (id && name) {
          onCompanyPeopleAdded({
            type: 'People',
            id,
            name,
            licenseNumber: v.applicatorLicenseNumber
          });
          resetForm();
          onClose();
        }
      });
    } else {
      const companyBody = {
        name: v.name,
        email: v.email,
        legalName: v.legalName,
        phone: v.phone,
        address: {
          line1: v.addressLineOne,
          line2: v.addressLineTwo,
          city: v.city,
          state: v.state,
          zipCode: v.zipCode,
          country: v.country
        },
        notes: v.notes
      };
      createCompany(companyBody).then(({ id, name }) => {
        if (id && name) {
          onCompanyPeopleAdded({ type: 'Company', id, name });
          resetForm();
          onClose();
        }
      });
    }
  };

  const isSaveButtonDisabled = ({ isValid, dirty }) =>
    !isValid || !dirty || isCreatingPerson || isCreatingCompany;

  return (
    <Formik
      initialValues={{}}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ isValid, dirty, submitForm, resetForm }) => {
        return (
          <Drawer
            maskClosable
            open={open}
            className="cwf-add-company-people-drawer"
            placement="right"
            closable={false}
            onClose={() => {
              resetForm();
              onClose();
            }}
            bodyStyle={{ padding: 0 }}
            footer={
              <div className="flex justify-between">
                <Button
                  type="outline"
                  onClick={() => {
                    resetForm();
                    onClose();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={isSaveButtonDisabled({ isValid, dirty })}
                  className="company-people-add-save-button"
                  type="primary"
                  onClick={submitForm}
                >
                  Save
                </Button>
              </div>
            }
            width="50%"
          >
            <div className="relative">
              <div className="absolute top-0 right-0 m-3">
                <button
                  type="button"
                  onClick={() => {
                    resetForm();
                    onClose();
                  }}
                >
                  <img src={Close} alt="Close icon" width={20} height={20} />
                </button>
              </div>
            </div>
            <div className="px-8 py-6">
              <div
                className="flex gap-4 items-center mb-6"
                data-testid="company-people-container"
              >
                <h1 className="text-xl font-semibold block mb-0">{title}</h1>
                <Select
                  className="w-140px"
                  onChange={setCompanyPeopleType}
                  value={companyPeopleType}
                  onDropdownVisibleChange={selectOpen =>
                    setCompanyPeopleTypeSelectOpen(selectOpen)
                  }
                  suffixIcon={
                    <img
                      src={ChevronDown}
                      style={{
                        transform: companyPeopleTypeSelectOpen
                          ? 'rotate(180deg)'
                          : null
                      }}
                      height={18}
                      width={18}
                      alt="Select dropdown icon"
                    />
                  }
                >
                  <Select.Option value="People">
                    <div className="flex items-center gap-2">
                      <img
                        src={PersonIcon}
                        alt="Person icon"
                        className="h-15px"
                      />{' '}
                      Person
                    </div>
                  </Select.Option>
                  <Select.Option value="Company">
                    <div className="flex items-center gap-2">
                      <img src={CompanyIcon} alt="Company icon" /> Company
                    </div>
                  </Select.Option>
                </Select>
              </div>
              {isCreatingPerson || isCreatingCompany ? (
                <div className="flex items-center justify-center h-100">
                  <Spin />
                </div>
              ) : (
                <div className="mb-8">
                  {companyPeopleType === 'People' ? (
                    <AddPerson />
                  ) : (
                    <AddCompany />
                  )}
                </div>
              )}
            </div>
          </Drawer>
        );
      }}
    </Formik>
  );
};

AddNewCompanyPeopleDrawer.defaultProps = {
  title: 'Create New Owner'
};

AddNewCompanyPeopleDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCompanyPeopleAdded: PropTypes.func.isRequired,
  title: PropTypes.string
};

export default AddNewCompanyPeopleDrawer;
