import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@agconnections/grow-ui';
import SourceFieldListItem from '../SourceFieldListItem';

const SourceFieldList = ({
  isLoading,
  sourceFields,
  selectedSourceField,
  onChange
}) => {
  return (
    <div
      data-testid="source-field-list"
      className="w-full pt-6 px-6 h-full flex flex-col"
    >
      <div className="mb-4">
        <span className="text-base">Source Fields</span>
        <p className="text-normal">
          Your fields matched to machinery data. Match operations to your crop
          zones
        </p>
      </div>
      <div className="flex flex-1 flex-col">
        <div className="pt-3 px-1 h-0 flex-grow overflow-y-auto">
          <div className="flex flex-col gap-y-2">
            {isLoading ? (
              <Spinner />
            ) : (
              sourceFields.map(sourceField => (
                <SourceFieldListItem
                  key={sourceField.fieldId}
                  sourceField={sourceField}
                  selectedSourceField={selectedSourceField}
                  onChange={onChange}
                />
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

SourceFieldList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  sourceFields: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedSourceField: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired
};

export default SourceFieldList;
