import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { paths } from 'routes/paths';
import { Button } from '@agconnections/grow-ui';

import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

const LandingPage = ({
  buttonText,
  breadcrumbText,
  ownerText,
  headerText,
  targetPath,
  LogoImg
}) => {
  const history = useHistory();

  return (
    <>
      <Breadcrumb hideCropSeasonDropdown disabled>
        <Breadcrumb.Item title="Integrations" value={breadcrumbText} isLast />
      </Breadcrumb>
      <div className="flex flex-col w-full items-start mt-5">
        <Button
          type="outline"
          className="absolute"
          icon={<FontAwesomeIcon fixedWidth size="1x" icon={faChevronLeft} />}
          onClick={() => history.replace(`${paths.integrations}`)}
        >
          Back
        </Button>
        <div className="mt-3 w-full border-b flex gap-5 items-center justify-between rounded-lg pt-2 pb-6">
          <div className="flex">
            <div className="bg-white rounded-xl shadow-md border p-6">
              <img src={LogoImg} alt="" />
            </div>
            <div className="pl-5 flex flex-col justify-center">
              <h3 className="font-bold text-2xl">{headerText}</h3>
              <h2>By {ownerText}</h2>
            </div>
          </div>
          <a
            className="gr-button"
            data-size="lg"
            data-type="primary"
            href={false}
            onClick={() => {
              history.push(`${paths.integrations}/${targetPath}`);
            }}
          >
            <span className="pl-6 pr-6">{buttonText}</span>
          </a>
        </div>
      </div>
    </>
  );
};

LandingPage.propTypes = {
  buttonText: PropTypes.string,
  breadcrumbText: PropTypes.string.isRequired,
  ownerText: PropTypes.string.isRequired,
  headerText: PropTypes.string.isRequired,
  targetPath: PropTypes.string.isRequired,
  LogoImg: PropTypes.shape({
    type: PropTypes.oneOf(['svg'])
  }).isRequired
};

LandingPage.defaultProps = {
  buttonText: 'Start a new Submission'
};

export default LandingPage;
