import { paths } from 'routes/paths';

const simplotIntegration = {
  name: 'Simplot',
  title: 'Simplot Integration',
  description:
    'The Simplot Integration allows you to share your field information to Simplot at anytime.',
  integrationType: 'Simplot',
  urlPath: `${paths.integrations}/Simplot/landing-page`,
  launchDarklyFlag: 'feIntegrationsModuleEnableSimplot'
};

const agrianIntegration = {
  name: 'Agrian',
  title: 'Agrian Integration',
  description:
    'Send application records to your NextGen Agrian account, to share with additional 3rd parties as needed.',
  integrationType: 'Agrian',
  urlPath: `${paths.integrations}/Agrian/landing-page`,
  launchDarklyFlag: 'feIntegrationsModuleEnableAgrian'
};

const agrianRecommendationIntegration = {
  name: 'Agrian Recommendations',
  title: 'Agrian Integration',
  description:
    'Import recommendations from your NextGen Agrian account to CropwiseFinancials, so they can be converted to tasks.',
  integrationType: 'Agrian',
  urlPath: `${paths.integrations}/AgrianRecommendation/landing-page`,
  launchDarklyFlag: [
    'releaseFeAgrianRecommendationIntegration',
    'releaseFeAgrianRecommendationIntegrationByOrgs'
  ]
};

const calAgIntegration = {
  name: 'CalAgPermits',
  title: 'CalAgPermits Integration',
  description:
    'Send application records to your CalAgPermits account, to share with additional 3rd parties as needed.',
  integrationType: 'CalAgPermits',
  urlPath: `${paths.integrations}/CalAgPermits/landing-page`,
  launchDarklyFlag: [
    'feIntegrationsModuleEnableCalAgPurPerOrg',
    'feIntegrationsModuleEnableCalAgPurGlobal'
  ]
};

export const mcCainIntegration = {
  name: 'McCain',
  title: 'McCain Foods Inc. Integration',
  description:
    'The McCain Foods Inc. Integration allows you to share your field information to McCain Foods at anytime.',
  integrationType: 'McCain',
  urlPath: `${paths.integrations}/McCain`,
  launchDarklyFlag: [
    'feIntegrationsModuleEnableMcCain',
    'feIntegrationsModuleEnableMcCainPerOrg'
  ]
};

export const johnDeereIntegration = {
  name: '',
  title: '',
  description:
    'Connect your data for John Deere Operation Center, CNH AFS Connect™, and more, powered by Cropwise Connect.',
  integrationType: 'Connect',
  launchDarklyFlag: ['beJohnDeereEnabledForAll', 'beJohnDeereEnabledPerOrg']
};

function evaluateFlag(flags, integration, orgId) {
  if (Array.isArray(integration.launchDarklyFlag)) {
    return integration.launchDarklyFlag.some(featureFlag => {
      const featureFlagValue = flags[featureFlag];
      if (Array.isArray(featureFlagValue)) {
        return featureFlagValue.includes(orgId);
      }
      return featureFlagValue;
    });
  }
  return flags[integration.launchDarklyFlag];
}

export function getProcessorIntegrations(flags, orgId) {
  return [
    mcCainIntegration,
    simplotIntegration,
    agrianIntegration
  ].filter(integration => evaluateFlag(flags, integration, orgId));
}

export function getRegulatoryIntegrations(flags, orgId) {
  return [
    calAgIntegration,
    agrianRecommendationIntegration
  ].filter(integration => evaluateFlag(flags, integration, orgId));
}

export function getConnectIntegrations(flags, orgId) {
  return [johnDeereIntegration].filter(integration =>
    evaluateFlag(flags, integration, orgId)
  );
}
