import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowBack } from 'assets/arrow_back.svg';
import { Button } from '@agconnections/grow-ui';
import { Context } from 'components/Store';
import {
  EXPORT_PDF_MAPS_STEP,
  FIELDS_TO_MASSIVE_ASSIGN,
  IS_EXPORT_PDF_MAPS_ACTIVE,
  IS_FARM_EDITED
} from 'reducers/reducer';
import { Col, Row } from 'syngenta-digital-cropwise-react-ui-kit';
import createAction from 'helpers/createAction';

const Title = ({ title, showGoBack, className }) => {
  const [
    { isMassAssignerActive, isExportPDFMapsActive, exportPDFMapsStep },
    dispatch
  ] = useContext(Context);

  const backHome = () => {
    createAction(dispatch, IS_FARM_EDITED, true);
    if (isExportPDFMapsActive) {
      if (exportPDFMapsStep === 1) {
        createAction(dispatch, EXPORT_PDF_MAPS_STEP, 0);
      } else {
        createAction(dispatch, IS_EXPORT_PDF_MAPS_ACTIVE, false);
        createAction(dispatch, FIELDS_TO_MASSIVE_ASSIGN, []);
      }
    }
  };

  return isMassAssignerActive ? null : (
    <Row className="flex flex-wrap justify-start ml-1">
      {showGoBack && (
        <Button
          ariaLabel="back"
          style={{ marginRight: '0.5rem' }}
          type="text"
          icon={<ArrowBack />}
          onClick={backHome}
        />
      )}
      <Col
        className={`${
          showGoBack ? 'text-xl' : 'text-3xl'
        } font-semibold ${className}`}
      >
        {title}
      </Col>
    </Row>
  );
};

Title.propTypes = {
  title: PropTypes.string.isRequired,
  showGoBack: PropTypes.bool.isRequired,
  className: PropTypes.string
};

Title.defaultProps = {
  className: ''
};

export default Title;
