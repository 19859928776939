import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Spinner } from '@agconnections/grow-ui';
import catchCancel from 'helpers/catchCancel';
import { Context } from 'components/Store';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import { parseServerError } from 'helpers/errorHelpers';
import { season as seasonApi } from 'utilities/api';
import useCropData from 'hooks/useCropData';
import TryAgainPageWrapper from 'components/ErrorHandler/TryAgainPageWrapper';
import { getAccessToken } from 'utilities/base-auth';
import { paths } from 'routes/paths';
import schema from '../helpers/schema';
import getCropSeasonDetails from './helpers/getCropSeasonDetails';
import CropSeasonForm from './components';

const blankValues = {
  name: '',
  date: null,
  crop: '',
  notes: ''
};

const CropSeason = () => {
  const [initialValues, setInitialValues] = useState();
  const [error, setError] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [saving, setSaving] = useState(false);
  const [pageError, setPageError] = useState();
  const [totalArea, setTotalArea] = useState(0);
  const { id } = useParams();
  const [{ organization }, dispatch] = useContext(Context);

  const history = useHistory();
  const { crops } = useCropData();
  const cropItems =
    crops?.map(crop => ({ key: crop.id, value: crop.name })) || [];

  useEffect(() => {
    if (!loaded) {
      if (id === 'create') {
        setInitialValues({
          ...blankValues
        });
        setLoaded(true);
      } else {
        getCropSeasonDetails(
          id,
          getAccessToken(),
          organization.id,
          setInitialValues,
          setPageError,
          setLoaded
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, id]);

  const onSubmit = async values => {
    const endDateEpoch = new Date(values?.date[1] || values?.date[0]).getTime();
    const startDateEpoch = new Date(values?.date[0]).getTime();
    const body = {
      ...(id !== 'create' && { id }),
      name: values.name,
      startDateEpoch,
      endDateEpoch,
      crops: values.crops?.map(({ id: cropId }) => cropId) ?? [],
      properties: values.properties,
      notes: values.notes
    };
    setSaving(true);
    if (id === 'create') {
      const { promise } = seasonApi.create(body);
      await promise
        .then(() => {
          setSaving(false);
          history.push(paths.cropSeasons);
        })
        .catch(catchCancel)
        .catch(err => {
          setSaving(false);
          if (err.response?.data?.displayErrorMessage) {
            if (err.response?.data?.code === 'ESEA017') {
              setError(
                'Field Name : Crop Zone Name already exists in a Crop Season within the selected dates. Please remove selection in order to save this Crop Season.'
              );
            } else {
              setError(err.response.data.displayErrorMessage);
            }
          } else {
            parseServerError(dispatch)(err);
          }
        });
    } else {
      const { promise } = seasonApi.update(body.id, body);
      await promise
        .then(() => {
          setSaving(false);
          history.push(paths.cropSeasons);
        })
        .catch(catchCancel)
        .catch(err => {
          setSaving(false);
          if (err.response?.data?.displayErrorMessage) {
            setError(err.response.data.displayErrorMessage);
          } else {
            parseServerError(dispatch)(err);
          }
        });
    }
  };
  return (
    <>
      <Breadcrumb disabled>
        <Breadcrumb.Item
          title="Crop Seasons"
          value="All Crop Seasons"
          to={paths.cropSeasons}
        />
        <Breadcrumb.Item
          title="Crop Season"
          value={id === 'create' ? 'New Crop Season' : 'Edit Crop Season'}
          isLast
        />
      </Breadcrumb>
      {!loaded || !initialValues ? (
        <Spinner />
      ) : (
        <TryAgainPageWrapper
          error={pageError}
          onReset={() => {
            history.go(0);
          }}
        >
          <CropSeasonForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            schema={schema}
            saving={saving}
            totalArea={totalArea}
            id={id}
            error={error}
            cropItems={cropItems}
            setTotalArea={setTotalArea}
            setError={setError}
            blankValues={blankValues}
          />
        </TryAgainPageWrapper>
      )}
    </>
  );
};

export default CropSeason;
