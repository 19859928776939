import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button } from '@agconnections/grow-ui';
import { Modal } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { WarningOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import FormattedNumber from 'components/FormattedNumber';
import EditOutlinedIcon from 'components/Icons/EditOutlinedIcon';
import RemoveCircleOutlinedIcon from 'components/Icons/RemoveCircleOutlinedIcon';
import { Context } from 'components/Store';
import { paths } from 'routes/paths';
import { emptyValue } from 'screens/ProductList/helpers/tableConstants';
import getProductQuantity from 'screens/ProductList/helpers/getProductQuantity';
import ProductTypeDisplay from 'screens/ProductList/helpers/ProductTypeDisplay';
import { getAbbreviatedUnit } from 'helpers/unitsAbbreviation';
import { condensor } from 'helpers/condensor';
import RemoveProductModal from 'screens/ProductList/components/RemoveProductModal';
import useInventoryProduct from 'screens/ProductList/hooks/useInventoryProduct';
import { ProductDetailsContext } from '../../context/ProductDetailsProvider';

const ProductDetailInfo = ({
  product,
  onEdit,
  setProductToRemove,
  setShowRemoveModal,
  showRemoveModal,
  handleRemove,
  toastHasNotBeenClosed,
  toastRenderContents,
  setToastHasNotBeenClosed
}) => {
  const { isRemoveLoading } = useInventoryProduct();
  const { isUpdateListPrice, selectedTaskIds } = useContext(
    ProductDetailsContext
  );
  const [organization] = useContext(Context);
  const {
    feProductListRemoveProduct,
    feProductListRemoveProductButtonByOrgId
  } = useFlags();

  const history = useHistory();

  const handleOnRemove = p => {
    setProductToRemove(p);
    setShowRemoveModal(true);
  };

  const closeRemoveModal = () => {
    setShowRemoveModal(false);
  };

  const onBack = () => {
    if (!isUpdateListPrice || !selectedTaskIds.length) {
      history.push(paths.products);
      return;
    }

    Modal.confirm({
      title: 'Cancel price update?',
      icon: <WarningOutlined />,
      content: 'The selected records will not be updated with the list price.',
      okText: 'Cancel update',
      cancelText: 'Continue selecting',
      okButtonProps: {
        style: {
          backgroundColor: '#14803C'
        }
      },
      onOk() {
        history.push(paths.products);
      }
    });
  };

  return (
    <>
      <div className="w-full flex items-center justify-between p-4 bg-white border-b">
        <div className="flex items-center gap-6">
          <Button
            onClick={onBack}
            icon={
              <FontAwesomeIcon
                icon={faChevronLeft}
                size="xs"
                style={{ marginRight: '4px' }}
              />
            }
            type="outline"
          >
            Back
          </Button>
          <h1 className="text-2xl font-semibold">{product.productName}</h1>
        </div>
        <div className="flex items-center gap-2">
          <Button
            icon={
              <EditOutlinedIcon
                color={isUpdateListPrice ? '#C2C7D0' : '#696F88'}
              />
            }
            onClick={() => onEdit(product)}
            tabIndex={0}
            type="outline"
            disabled={isUpdateListPrice}
          >
            Edit
          </Button>
          {feProductListRemoveProduct ||
          feProductListRemoveProductButtonByOrgId?.includes(
            organization?.organization?.id
          ) ? (
            <Button
              icon={
                <RemoveCircleOutlinedIcon
                  color={isUpdateListPrice ? '#C2C7D0' : '#696F88'}
                />
              }
              type="outline"
              disabled={isUpdateListPrice}
              onClick={() => {
                handleOnRemove(product.productId);
              }}
            >
              Remove
            </Button>
          ) : null}
          <RemoveProductModal
            open={showRemoveModal}
            itemType="Product"
            close={closeRemoveModal}
            handleRemove={handleRemove}
            isRemoveLoading={isRemoveLoading}
            setToastHasNotBeenClosed={setToastHasNotBeenClosed}
            toastHasNotBeenClosed={toastHasNotBeenClosed}
            toastRenderContents={toastRenderContents}
          />
        </div>
      </div>
      <section className="w-full p-4">
        <div className="flex gap-4">
          <div className="grid items-center w-56 px-4 py-3 bg-white rounded-lg shadow-sm-card">
            <div>
              <span className="text-2xl font-semibold">
                {getProductQuantity(product)}
                <span className="ml-1 text-base font-normal">
                  {getAbbreviatedUnit(product.stdPackageUnit)}
                </span>
              </span>
              <p className="text-base text-neutral-60 overflow-hidden whitespace-no-wrap">
                Total available quantity
              </p>
            </div>
          </div>
          <div className="flex-1 px-4 py-4 bg-white rounded-lg shadow-lg">
            <div className="flex flex-row gap-x-9 items-start">
              <div className="flex flex-col ">
                <span className="flex text-sm text-neutral-60">
                  Manufacturer
                </span>
                <span className="text-base text-wrap   flex-wrap break-words">
                  {product.custom ? <span>Custom • </span> : null}
                  {product.manufacturerName}
                </span>
              </div>

              <div className="flex flex-col ">
                <span className="flex text-sm text-neutral-60 whitespace-no-wrap">
                  Product Type
                </span>
                <div className="flex items-start text-base whitespace-no-wrap">
                  <ProductTypeDisplay
                    productType={product.productType}
                    iconSize={24}
                  />
                </div>
              </div>

              <div className="flex flex-col ">
                <span className="flex text-sm text-neutral-60 whitespace-no-wrap">
                  List Price
                </span>
                <span className="flex text-base whitespace-no-wrap">
                  {product.averagePricePerUnit ? (
                    <>
                      <FormattedNumber unit="usCurrency" roundedTo={2}>
                        {product.averagePricePerUnit}
                      </FormattedNumber>{' '}
                      /{getAbbreviatedUnit(product.stdUnit)}
                    </>
                  ) : (
                    emptyValue.none
                  )}
                </span>
              </div>

              <div className="flex flex-col ">
                <span className="flex text-sm text-neutral-60 whitespace-no-wrap">
                  EPA Number
                </span>
                <span className="flex text-base whitespace-no-wrap">
                  {product.registrationNumber
                    ? condensor(product.registrationNumber)
                    : emptyValue.none}
                </span>
              </div>
              {!!product?.packageWeight && (
                <div className="flex flex-col ">
                  <span className="flex text-sm text-neutral">
                    Package Weight
                  </span>
                  <span className="flex text-base">
                    <FormattedNumber roundedTo={2}>
                      {product.packageWeight}
                    </FormattedNumber>
                    {` / lbs per ${product.stdPackageUnit}`}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

ProductDetailInfo.propTypes = {
  product: PropTypes.shape({
    productId: PropTypes.string,
    productName: PropTypes.string,
    productType: PropTypes.string,
    available: PropTypes.number,
    averagePricePerUnit: PropTypes.number,
    averagePriceUnit: PropTypes.string,
    manufacturerName: PropTypes.string,
    custom: PropTypes.bool,
    stdPackageUnit: PropTypes.string,
    packageWeight: PropTypes.number,
    stdUnit: PropTypes.string,
    registrationNumber: PropTypes.string
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
  setProductToRemove: PropTypes.func.isRequired,
  setShowRemoveModal: PropTypes.func.isRequired,
  showRemoveModal: PropTypes.bool.isRequired,
  handleRemove: PropTypes.func.isRequired,
  toastHasNotBeenClosed: PropTypes.func.isRequired,
  toastRenderContents: PropTypes.func.isRequired,
  setToastHasNotBeenClosed: PropTypes.func.isRequired
};

export default ProductDetailInfo;
