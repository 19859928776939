import * as Yup from 'yup';

/**
 * Returns a Yup validation schema for a positive number field that is required.
 *
 * @param {string} fieldLabel - The label of the field.
 * @returns {Yup.NumberSchema} - The Yup validation schema.
 */
export function positiveNumberRequired(fieldLabel) {
  return Yup.number()
    .required(`${fieldLabel} is required`)
    .typeError(`${fieldLabel} must be a number`)
    .test(
      'is-positive',
      `${fieldLabel} must be a positive number`,
      value => value > 0
    );
}

/**
 * Returns a Yup validation schema for a positive number that can be nullable.
 *
 * @param {string} fieldLabel - The label of the field being validated.
 * @returns {Yup.NumberSchema} - The Yup validation schema for a positive number that can be nullable.
 */
export function positiveNumberNullable(fieldLabel) {
  return Yup.number()
    .typeError(`${fieldLabel} must be a number`)
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, `${fieldLabel} must be a positive number`);
}

/**
 * Checks if a value is positive.
 *
 * @param {string} value - The value to check.
 * @returns {boolean} Returns true if the value is positive, false otherwise.
 */
export const isPositive = value => Number(value) > 0;
