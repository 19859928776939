import React from 'react';
import PropTypes from 'prop-types';
import { GeoJSONLayer } from 'react-mapbox-gl';
import {
  conditionLayerSelected,
  conditionLayerCustom
} from '../../../PropertyMap/utilities/mapboxLayerHelpers';

const DynamicLayer = ({
  geoJSON,
  onClickShape,
  labelKey,
  isGeoJSONLabelEnabled
}) => {
  if (!isGeoJSONLabelEnabled) {
    return (
      <div data-testid="geojson-layer">
        <GeoJSONLayer
          fillOnClick={onClickShape}
          data={geoJSON}
          fillPaint={{
            'fill-color': [
              'case',
              conditionLayerSelected,
              'rgba(49, 180, 242, 0.05)',
              'rgba(255, 255, 255, 0.15)'
            ],
            'fill-antialias': true
          }}
          linePaint={{
            'line-color': 'rgba(255, 255, 255, 1)',
            'line-width': 3
          }}
        />
      </div>
    );
  }

  const fillSortKey = geoJSON.features.length;

  return (
    <div data-testid="geojson-layer-with-labels">
      <GeoJSONLayer
        fillOnClick={onClickShape}
        data={geoJSON}
        fillPaint={{
          'fill-color': [
            'case',
            conditionLayerCustom,
            'transparent',
            conditionLayerSelected,
            'rgba(49, 180, 242, 0.05)',
            'rgba(255, 255, 255, 0.3)'
          ],
          'fill-antialias': true
        }}
        fillSortKey={['get', 'sortKey']}
        linePaint={{
          'line-color': [
            'case',
            conditionLayerCustom,
            'transparent',
            '#FFFFFF'
          ],
          'line-width': 2
        }}
        symbolLayout={{
          'text-field': ['get', labelKey],
          'text-anchor': 'center',
          'text-justify': 'auto',
          'text-variable-anchor': ['left'],
          'text-radial-offset': 0,
          'text-size': 20,
          'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
          'symbol-z-order': 'auto',
          'symbol-sort-key': fillSortKey,
          'text-allow-overlap': false
        }}
        symbolPaint={{
          'text-color': 'white'
        }}
      />
    </div>
  );
};

DynamicLayer.propTypes = {
  geoJSON: PropTypes.objectOf().isRequired,
  onClickShape: PropTypes.func.isRequired,
  labelKey: PropTypes.string.isRequired,
  isGeoJSONLabelEnabled: PropTypes.bool.isRequired
};

export default DynamicLayer;
