import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { Button, ButtonType } from 'syngenta-digital-cropwise-react-ui-kit';
import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
import { format } from 'date-fns';

import { ReactComponent as MapboxLogo } from 'assets/mapbox_logo_black.svg';
import { ReactComponent as CropwiseFinancialsLogo } from 'assets/cropwise_financials_logo_black.svg';
import { ReactComponent as DownloadIcon } from 'assets/download_outlined_green.svg';
import { Context } from 'components/Store';
import { getFieldCrops } from 'screens/Property/helpers/propertyDataHelpers';
import CropLegend from '../CropLegend';

const WithExportPreview = ({ fields, children }) => {
  const [{ isExportPDFMapsActive, exportPDFMapsStep, cropColors }] = useContext(
    Context
  );

  const [isExporting, setIsExporting] = useState(false);

  useEffect(() => {
    async function exportPDF() {
      if (!isExporting) return;
      const input = document.getElementById('map-pdf-preview');
      const canvas = await html2canvas(input);
      const imgData = canvas.toDataURL('image/png');
      const pdf = new JsPDF({
        orientation: 'landscape',
        unit: 'px',
        format: [canvas.width, canvas.height]
      });
      pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);

      const fileName = `Map_${format(new Date(), 'yyyy-MM-dd-HHmm')}.pdf`;
      pdf.save(fileName);
      setIsExporting(false);
    }
    exportPDF();
  }, [isExporting]);

  if (!isExportPDFMapsActive || exportPDFMapsStep !== 1) {
    return <>{children}</>;
  }

  const crops = getFieldCrops(fields);

  const handleDownloadPDF = () => {
    setIsExporting(true);
  };

  const showCropLegend = !!fields.find(({ cropzones }) => cropzones.length);

  return (
    <div
      className="flex flex-col flex-grow items-center justify-center pt-2"
      style={{ backgroundColor: '#f2f4f6' }}
    >
      <div
        id="map-pdf-preview"
        className="flex flex-col bg-white shadow-sm-card"
        style={{
          width: 'calc(100% - 16px)',
          aspectRatio: '11 / 8.5', // 11 inch width x 8.5 inch height
          padding: 'calc((100% - 16px) / 22)', // 0.5 inch margins (11in / 22 = 0.5in)
          margin: 'auto',
          position: 'relative'
        }}
      >
        {children}
        <Row className="bg-white h-72px items-end" justify="space-between">
          {/* Footer content */}
          <Col className="items-center" />
          <Col>
            <Row className="items-end" gutter={8}>
              <MapboxLogo />
              <CropwiseFinancialsLogo />
              <CropLegend
                crops={crops}
                cropColors={cropColors}
                show={showCropLegend}
                isExporting={isExporting}
                style={{
                  bottom: 29,
                  right: 9
                }}
              />
            </Row>
          </Col>
        </Row>
      </div>
      <div className="flex justify-center my-6 p-4 bg-white rounded-lg shadow-sm-card">
        <Row gutter={8} justify="center">
          {/* To implement in future stories */}
          {/* <Col>
            <Button type={ButtonType.primary} ghost size="large">
              Email
            </Button>
          </Col>
          <Col>
            <Button type={ButtonType.primary} ghost size="large">
              Print
            </Button>
          </Col> */}
          <Col>
            <Button
              type={ButtonType.primary}
              ghost
              size="large"
              onClick={handleDownloadPDF}
            >
              <Row gutter={4} align="middle">
                <Col>
                  <DownloadIcon />
                </Col>
                <Col>Download PDF</Col>
              </Row>
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

WithExportPreview.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      area: PropTypes.number.isRequired
    })
  ).isRequired,
  children: PropTypes.node.isRequired
};

export default WithExportPreview;
