import React, { useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'syngenta-digital-cropwise-react-ui-kit';
import { ProductDetailsContext } from 'screens/ProductList/ProductDetails/context/ProductDetailsProvider';

const TaskProductTable = ({
  rowKey,
  columns,
  dataSource,
  pagination,
  onChange,
  tableLayout,
  banner,
  loading
}) => {
  const {
    isUpdateListPrice,
    selectedTaskIds,
    setSelectedTaskIds,
    isInfoRow,
    setIsInfoRow,
    setShowInfoRow
  } = useContext(ProductDetailsContext);
  const currentPage = pagination.current;

  useEffect(() => {
    const keysPerPage = dataSource.map(row => row[rowKey]);
    const allSelectedOnPage = keysPerPage.every(key =>
      selectedTaskIds.includes(key)
    );

    setIsInfoRow(prevState => ({
      ...prevState,
      [currentPage]: allSelectedOnPage
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTaskIds, currentPage, dataSource]);

  const handleRowSelectionChange = selectedRowKeys => {
    setSelectedTaskIds(selectedRowKeys);
  };

  const rowSelection = {
    columnWidth: 60,
    preserveSelectedRowKeys: true,
    selectedRowKeys: selectedTaskIds,
    type: 'checkbox',
    onChange: selectedRowKeys => {
      handleRowSelectionChange(selectedRowKeys);
    },
    getCheckboxProps: record => ({
      disabled: record?.isInfoRow,
      style: record?.isInfoRow ? { display: 'none' } : {}
    })
  };

  useEffect(() => {
    setShowInfoRow(isInfoRow[currentPage]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, isInfoRow]);

  const dataSourceProcessed = useMemo(() => {
    if (isInfoRow[currentPage]) {
      return [
        {
          id: 'info-row',
          key: 'info-row',
          isInfoRow: true
        },
        ...dataSource
      ];
    }
    if (banner) {
      return [
        {
          id: 'banner-row',
          key: 'banner-row',
          banner
        },
        ...dataSource
      ];
    }
    return dataSource;
  }, [dataSource, isInfoRow, banner, currentPage]);

  const paginationProcessed = useMemo(() => {
    if (isInfoRow[currentPage] || banner) {
      return { ...pagination, pageSize: 21 };
    }
    return pagination;
  }, [isInfoRow, pagination, banner, currentPage]);

  return (
    <Table
      rowKey={rowKey}
      bordered={false}
      columns={columns}
      loading={loading}
      dataSource={dataSourceProcessed}
      pagination={paginationProcessed}
      rowSelection={isUpdateListPrice ? rowSelection : undefined}
      rowClassName={record =>
        record.isInfoRow || record.banner ? 'hide-checkbox' : ''
      }
      onChange={onChange}
      scroll={{
        y: 320
      }}
      tableLayout={tableLayout}
    />
  );
};

TaskProductTable.propTypes = {
  rowKey: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  dataSource: PropTypes.arrayOf(PropTypes.object).isRequired,
  pagination: PropTypes.shape({
    current: PropTypes.number.isRequired,
    pageSize: PropTypes.number,
    total: PropTypes.number
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  tableLayout: PropTypes.string.isRequired,
  banner: PropTypes.element,
  loading: PropTypes.bool.isRequired
};

TaskProductTable.defaultProps = {
  banner: null
};

export default TaskProductTable;
