import React, { useState, useMemo, useEffect, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Select } from 'syngenta-digital-cropwise-react-ui-kit';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Formik, Form } from 'formik';
import Breadcrumb from 'components/App/AppShell/components/Breadcrumb';
import CropSelect from 'components/CropSelect';
import { Context } from 'components/Store';
import { ReactComponent as ChevronDown } from 'assets/chevron_down.svg';
import { AmplitudeContext } from 'utilities/amplitude/useAmplitude';
import useLoads from 'hooks/useLoads';
import { paths } from 'routes/paths';
import { MEASUREMENT_UNITS } from 'helpers/unitsAbbreviation';
import { selectCropSeason } from 'utilities/updated-permissions';
import useCropSeasons from 'hooks/useCropSeasons';
import featureFlagRenderItem from 'helpers/featureFlagRenderItem';
import { getBushelWeight } from 'helpers/getBushelWeight';
import FullHeightSpinner from './components/FullHeightSpinner';
import getCurrentDateAt8AM from './helpers/getCurrentDateAt8AM';
import FooterButtons from './components/FooterButtons';
import DefaultAllCropsTemplate from './components/DefaultAllCropsTemplate';
import DefaultRowCropTemplate from './components/DefaultRowCropTemplate';
import DefaultSpecialtyCropTemplate from './components/DefaultSpecialtyCropTemplate';
import ADD_YIELD_SCHEMAS from './helpers/addYieldValidationSchema';
import LoadsManager from './components/LoadsManager';
import CreateNewLoadWidget from './components/CreateNewLoadWidget';
import CropChangeConfirmation from './components/CropChangeConfirmation';
import TemplateChangeConfirmation from './components/TemplateChangeConfirmation';
import ToastHandler from '../Loads/components/ToastHandler';
import processYieldTickets from './helpers/processYieldTickets';
import EditFooterButtons from './components/EditFooterButtons';
import getInitialLoadName from './helpers/getInitialLoadName';
import EmptyView from '../components/EmptyView';
import { TEMPLATE_DEFAULT_VALUES, TEMPLATE_TYPE } from '../constants';

export const YIELD_TEMPLATES = {
  [TEMPLATE_TYPE.ALL_CROPS]: {
    component: DefaultAllCropsTemplate,
    value: TEMPLATE_TYPE.ALL_CROPS,
    label: 'Default All Crops Template',
    defaultValues: TEMPLATE_DEFAULT_VALUES[TEMPLATE_TYPE.ALL_CROPS],
    validationSchema: ADD_YIELD_SCHEMAS[TEMPLATE_TYPE.ALL_CROPS]
  },
  [TEMPLATE_TYPE.ROW_CROP]: {
    component: DefaultRowCropTemplate,
    value: TEMPLATE_TYPE.ROW_CROP,
    label: 'Default Row Crop Template',
    defaultValues: TEMPLATE_DEFAULT_VALUES[TEMPLATE_TYPE.ROW_CROP],
    validationSchema: ADD_YIELD_SCHEMAS[TEMPLATE_TYPE.ROW_CROP]
  },
  [TEMPLATE_TYPE.SPECIALTY]: {
    component: DefaultSpecialtyCropTemplate,
    value: TEMPLATE_TYPE.SPECIALTY,
    label: 'Default Specialty Crop Template',
    defaultValues: TEMPLATE_DEFAULT_VALUES[TEMPLATE_TYPE.SPECIALTY],
    validationSchema: ADD_YIELD_SCHEMAS[TEMPLATE_TYPE.SPECIALTY]
  }
};

const NOTIFICATION_TEXT = {
  loadEdited: {
    popupTitle: 'Changes saved',
    widgetTitle: 'Changes Saved!'
  },
  loadCreated: {
    popupTitle: 'Load added to list',
    widgetTitle: 'Added to Loads List!'
  },
  loadDeleted: {
    popupTitle: 'Load deleted',
    widgetTitle: 'Load deleted!'
  }
};

const AddYield = () => {
  const history = useHistory();
  const { saveAllLoads } = useLoads();
  const { cropSeasons } = useCropSeasons();
  const location = useLocation();
  const {
    releaseYieldTemplates,
    releaseYieldTemplatesByOrg,
    releaseFeCondensedCropSeasons,
    releaseFeCondensedCropSeasonsByOrg
  } = useFlags();

  const [afterSaveNotificationType, setAfterSaveNotificationType] = useState({
    popupTitle: 'Changes saved',
    widgetTitle: 'Changes Saved!'
  });
  const [showCreateNewLoadWidget, setShowCreateNewLoadWidget] = useState(false);
  const [selectedTemplateValue, setSelectedTemplateValue] = useState(
    YIELD_TEMPLATES[TEMPLATE_TYPE.ALL_CROPS].value
  );
  const [yieldTickets, setYieldTickets] = useState([]);
  const [isSavingAllLoads, setIsSavingAllLoads] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);

  // If coming from Commodity Summary, we should use ?crop URL param,
  // to satisfy the AC "If coming from Commodity Summary, crop should match the selection from there"
  const queryParams = new URLSearchParams(location.search);
  const cropId = queryParams.get('cropId');
  // Initialize selectedCropValue with an object that has key and label properties
  const [selectedCropId, setSelectedCropId] = useState(cropId);
  const [resetSubimissionChange, setResetSubimissionChange] = useState(false);
  const [selectedTempCropId, setSelectedTempCropId] = useState(cropId);
  const [selectedTempTemplate, setSelectedTempTemplate] = useState(
    selectedTemplateValue
  );
  const [selectedCropSeasonId] = JSON.parse(
    localStorage.getItem('selectedCropSeasons')
  );
  const [showCropChangeConfirmation, setShowCropChangeConfirmation] = useState(
    false
  );
  const [
    showTemplateChangeConfirmation,
    setShowTemplateChangeConfirmation
  ] = useState(false);

  const [
    {
      organization,
      loadingOrg,
      loggedInUserOrgPermission: { name: userName }
    },
    dispatch
  ] = useContext(Context);

  const season = useMemo(
    () =>
      cropSeasons?.find(cropSeason => cropSeason?.id === selectedCropSeasonId),
    [cropSeasons, selectedCropSeasonId]
  );
  const selectedCropName = useMemo(
    () => season?.crops?.find(crop => crop.id === selectedCropId)?.name,
    [season, selectedCropId]
  );

  const isCondensedCropSeasonEnabled = featureFlagRenderItem(
    releaseFeCondensedCropSeasons,
    releaseFeCondensedCropSeasonsByOrg,
    organization?.id
  );

  useEffect(() => {
    selectCropSeason(
      dispatch,
      [selectedCropSeasonId],
      isCondensedCropSeasonEnabled
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedCropSeasonId]);

  useEffect(() => {
    if (!season?.crops || cropId) return;
    // if URL param cropId is undefined,
    // select the first crop alphabetically
    const sortedCrops = season?.crops?.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    const [firstCrop] = sortedCrops || [];
    if (firstCrop) setSelectedCropId(firstCrop.id);
  }, [season?.crops, cropId]);

  const YieldTemplate = YIELD_TEMPLATES[selectedTemplateValue]?.component;

  const isTemplateChangeEnabled = featureFlagRenderItem(
    releaseYieldTemplates,
    releaseYieldTemplatesByOrg,
    organization.id
  );

  useEffect(() => {
    if (isTemplateChangeEnabled) return;
    const defaultTemplate = YIELD_TEMPLATES[TEMPLATE_TYPE.ALL_CROPS].value;
    if (selectedTemplateValue !== defaultTemplate) {
      setSelectedTemplateValue(defaultTemplate); // reset to default template if feature flag is turned off
    }
  }, [isTemplateChangeEnabled, selectedTemplateValue]);

  const getDefaultLoadUnit = cropName => {
    const isBushelExists = getBushelWeight(cropName);
    return isBushelExists ? MEASUREMENT_UNITS.BUSHEL : MEASUREMENT_UNITS.POUND;
  };

  const initialFormValues = useMemo(
    () => ({
      grossWeight: null,
      tareWeight: null,
      netWeight: null,
      loadUnit: getDefaultLoadUnit(selectedCropName),
      weightUnit: MEASUREMENT_UNITS.POUND,
      cropId: selectedCropId,
      date: getCurrentDateAt8AM(),
      name: getInitialLoadName(userName),
      sources: [{ id: '' }],
      destination: { id: '' },
      load: null,
      loadTemporaryId: Date.now(),
      salePrice: null,
      grossRevenue: null,
      truckId: null,
      driverId: null,
      TruckAndDriverFieldResetValue: true,
      ...YIELD_TEMPLATES[selectedTemplateValue].defaultValues
    }),
    [selectedCropName, selectedTemplateValue, selectedCropId, userName]
  );

  const amplitude = useContext(AmplitudeContext);

  const handleTemplateChange = value => {
    if (value === selectedTemplateValue) return;
    setShowTemplateChangeConfirmation(true);
    setSelectedTempTemplate(value);
    amplitude.sendEventToAmplitude(
      amplitude.events.epic.YieldV2.addYield.selectTemplate,
      {
        organizationName: organization?.name,
        organizationId: organization?.id,
        template: value
      }
    );
  };
  const handleCropChange = value => {
    if (value !== selectedCropId) {
      setShowCropChangeConfirmation(true);
      setSelectedTempCropId(value);
    }
  };

  const onSaveAllLoads = async unsavedLoadToInclude => {
    let loads = yieldTickets;
    // adding additional checks as it created bug in past
    if (unsavedLoadToInclude?.name && unsavedLoadToInclude?.load) {
      const [cropSeasonId] = JSON.parse(
        localStorage.getItem('selectedCropSeasons')
      );
      loads = [
        ...loads,
        {
          ...unsavedLoadToInclude,
          cropSeasonId,
          cropId: selectedCropId,
          cropName: selectedCropName
        }
      ];
    }
    setIsSavingAllLoads(true);
    const data = processYieldTickets(loads);

    amplitude.sendEventToAmplitude(
      amplitude.events.epic.YieldV2.addYield.saveLoads,
      {
        numberOfLoads: data.length,
        organizationId: organization?.id,
        ...data.reduce(
          (acc, load) => {
            acc.grossWeight += load?.grossWeight || 0;
            acc.tareWeight += load?.tareWeight || 0;
            acc.netWeight += load?.netWeight || 0;
            return acc;
          },
          {
            grossWeight: 0,
            tareWeight: 0,
            netWeight: 0
          }
        )
      }
    );

    const response = await saveAllLoads(data);
    if (!response) {
      setShowErrorToast(true);
      setIsSavingAllLoads(false);
    } else {
      history.push({
        pathname: `${paths.yieldV2}/loads`,
        toast: {
          type: 'success',
          title: 'All Loads saved.',
          message: '',
          showToast: true
        }
      });
    }
  };

  const isEditingLoad = currentLoadTempId =>
    yieldTickets.some(load => load.loadTemporaryId === currentLoadTempId);

  const onSubmit = (values, { resetForm }) => {
    const [cropSeasonId] = JSON.parse(
      localStorage.getItem('selectedCropSeasons')
    );
    const yieldTicket = {
      ...values,
      templateType: selectedTemplateValue,
      grossRevenue: Number(values.grossRevenue),
      cropSeasonId,
      cropId: selectedCropId,
      cropName: selectedCropName
    };

    if (isEditingLoad(values.loadTemporaryId)) {
      const newLoads = [...yieldTickets];
      const index = newLoads.findIndex(
        load => load.loadTemporaryId === values.loadTemporaryId
      );
      newLoads[index] = yieldTicket;
      setYieldTickets(newLoads);
      setAfterSaveNotificationType(NOTIFICATION_TEXT.loadEdited);
    } else {
      setYieldTickets(prevState => [...prevState, yieldTicket]);
      setAfterSaveNotificationType(NOTIFICATION_TEXT.loadCreated);
    }
    // we remember user choice for weight units and source/destination
    const keysToRemember = [
      'weightUnit',
      'loadUnit',
      'sources',
      'sourceType',
      'destination',
      'totalArea',
      'cropId',
      'templateType'
    ];
    const newFormValues = keysToRemember.reduce((obj, key) => {
      return { ...obj, [key]: values[key] };
    }, {});
    resetForm({
      values: {
        ...newFormValues,
        date: getCurrentDateAt8AM(),
        name: getInitialLoadName(userName),
        load: null,
        loadTemporaryId: Date.now(),
        ...YIELD_TEMPLATES[selectedTemplateValue].defaultValues
      }
    });
  };

  const onDeleteYieldTicket = item => {
    setYieldTickets(
      yieldTickets.filter(
        ticket => ticket.loadTemporaryId !== item.loadTemporaryId
      )
    );
    setAfterSaveNotificationType(NOTIFICATION_TEXT.loadDeleted);
  };

  const closeCreateNewLoadWidget = () => {
    setShowCreateNewLoadWidget(false);
  };

  if (season && !season?.cropZoneIds?.length) {
    return (
      <>
        <Breadcrumb hideCropSeasonDropdown={false} disabled disabledCropSeasons>
          <Breadcrumb.Item title="Yield" value="Add Yield Loads" isLast />
        </Breadcrumb>
        <div
          data-testid="empty-cropseason"
          className="my-6 py-20 bg-white rounded-lg shadow-sm-card border-1 border-neutral-30"
        >
          <EmptyView hasNoCropsinSelectedSeason />
        </div>
      </>
    );
  }

  if (!season || !selectedCropId) return null;

  const getDefaultSubimissionValues = updatedValues => ({
    values: {
      ...initialFormValues,
      ...updatedValues
    }
  });
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialFormValues}
        validationSchema={
          YIELD_TEMPLATES[selectedTemplateValue].validationSchema
        }
        onSubmit={onSubmit}
        validateOnChange
      >
        {({ resetForm, values, handleSubmit, validateForm }) => (
          <>
            <TemplateChangeConfirmation
              open={showTemplateChangeConfirmation}
              onCancel={() => setShowTemplateChangeConfirmation(false)}
              onConfirm={() => {
                setShowTemplateChangeConfirmation(false);
                setSelectedTemplateValue(selectedTempTemplate);
                resetForm(
                  getDefaultSubimissionValues({
                    ...YIELD_TEMPLATES[selectedTempTemplate].defaultValues
                  })
                );
                setYieldTickets([]);
                setResetSubimissionChange(true);
              }}
            />

            <CropChangeConfirmation
              open={showCropChangeConfirmation}
              onCancel={() => setShowCropChangeConfirmation(false)}
              onConfirm={() => {
                setShowCropChangeConfirmation(false);
                setSelectedCropId(selectedTempCropId);
                const selectedTempName = season?.crops?.find(
                  crop => crop.id === selectedTempCropId
                )?.name;
                resetForm(
                  getDefaultSubimissionValues({
                    cropId: selectedTempCropId,
                    loadUnit: getDefaultLoadUnit(selectedTempName)
                  })
                );
                setYieldTickets([]);
                setResetSubimissionChange(true);
              }}
            />
            <Form className="h-full">
              <Breadcrumb
                disabled
                disabledCropSeasons
                hideCropSeasonDropdown={false}
              >
                <Breadcrumb.Item title="Yield" value="Add Yield Loads" isLast />
              </Breadcrumb>
              <div className="flex h-full">
                <div className="px-4 flex-1 ">
                  <div className="pl-8 pb-4 pt-6 flex gap-6">
                    {isTemplateChangeEnabled && (
                      <div className="min-w-60 flex flex-col gap-0.5">
                        <label
                          htmlFor="template-select"
                          className="text-neutral-60 tracking-tighter text-sm"
                        >
                          Template
                        </label>
                        <Select
                          id="template-select"
                          options={Object.values(YIELD_TEMPLATES)}
                          value={selectedTemplateValue}
                          onSelect={handleTemplateChange}
                          size="middle"
                          loading={false}
                          suffixIcon={<ChevronDown />}
                          disabled={isSavingAllLoads}
                        />
                      </div>
                    )}
                    <div className="min-w-60 flex flex-col gap-0.5 items-start">
                      <CropSelect
                        selectedCropId={selectedCropId}
                        onCropChange={handleCropChange}
                        crops={season?.crops}
                        disabled={isSavingAllLoads}
                      />
                    </div>
                  </div>
                  <div
                    style={{ maxHeight: 'calc(100% - 100px)' }}
                    className="shadow-elevation-2 bg-white rounded-lg relative flex-1 flex flex-col overflow-auto"
                  >
                    {isSavingAllLoads || loadingOrg ? (
                      <FullHeightSpinner
                        text={isSavingAllLoads ? 'Saving Loads...' : ''}
                      />
                    ) : (
                      <div className="overflow-y-auto p-8 pb-20">
                        {showCreateNewLoadWidget &&
                        !isEditingLoad(values.loadTemporaryId) ? (
                          <CreateNewLoadWidget
                            unSavedTicketsCount={yieldTickets.length}
                            closeCreateNewLoadWidget={closeCreateNewLoadWidget}
                            onSaveAllLoads={() => onSaveAllLoads()}
                            widgetTitle={afterSaveNotificationType.widgetTitle}
                          />
                        ) : (
                          <YieldTemplate selectedCropName={selectedCropName} />
                        )}
                      </div>
                    )}
                    {isEditingLoad(values.loadTemporaryId) ? (
                      <div className="absolute inset-x-0 bottom-0 bg-white">
                        <EditFooterButtons
                          onSaveAndCreateNew={() => {
                            handleSubmit();
                            setShowCreateNewLoadWidget(false);
                          }}
                          onSave={() =>
                            validateForm().then(_errors => {
                              if (Object.keys(_errors).length === 0) {
                                handleSubmit();
                                setShowCreateNewLoadWidget(true);
                              }
                            })
                          }
                        />
                      </div>
                    ) : (
                      <div
                        className={`absolute inset-x-0 bottom-0 bg-white ${
                          showCreateNewLoadWidget ? 'hidden' : ''
                        }`}
                      >
                        <FooterButtons
                          disabled={isSavingAllLoads}
                          showCreateNewLoadWidget={() =>
                            setShowCreateNewLoadWidget(true)
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="max-w-80 w-full h-full">
                  <LoadsManager
                    list={yieldTickets}
                    onDeleteYieldTicket={onDeleteYieldTicket}
                    onSaveAllLoads={onSaveAllLoads}
                    popupTitle={afterSaveNotificationType.popupTitle}
                    isEditingLoad={isEditingLoad(values.loadTemporaryId)}
                    isDisabled={isSavingAllLoads}
                    resetSubimissionChange={resetSubimissionChange}
                    setResetSubimissionChange={setResetSubimissionChange}
                  />
                </div>
              </div>
            </Form>
          </>
        )}
      </Formik>
      <ToastHandler
        toastType="error"
        showToast={showErrorToast}
        onCloseHandler={() => setShowErrorToast(false)}
        title="Loads not saved."
        description="An error prevented your loads from being saved. Please try again"
      />
    </>
  );
};

export default AddYield;
