import React, { useState } from 'react';
import { Input, Select } from 'syngenta-digital-cropwise-react-ui-kit';
import useLandingPageData from 'hooks/useLandingPageData';
import ChevronDown from 'assets/chevron_down.svg';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { useFormikContext } from 'formik';
import { DatePicker, TextArea } from '@agconnections/grow-ui';
import AddressFields from '../AddressFields';

const AddPerson = () => {
  const { values, handleChange, errors } = useFormikContext();
  const [companiesSelectOpen, setCompaniesSelectOpen] = useState(false);
  const { loaded, items: companies } = useLandingPageData('worker/companies', [
    'company.name'
  ]);
  return (
    <>
      <div className="flex flex-col gap-1 mb-8 w-1/2 pr-4">
        <label
          htmlFor="owner-name"
          className="text-neutral-60 tracking-tighter text-sm"
        >
          Name<span className="text-remove">*</span>
        </label>
        <Input
          required
          name="name"
          id="owner-name"
          type="default"
          size="middle"
          value={values.name}
          status={errors?.name && 'error'}
          caption={errors?.name}
          onChange={handleChange}
        />
      </div>
      <h3 className="text-base font-semibold block mb-4">Basic Info</h3>

      <div className="flex">
        <div className="flex flex-col gap-1 mb-5 w-full mr-8">
          <label
            htmlFor="email"
            className="text-neutral-60 tracking-tighter text-sm"
          >
            Email Address
          </label>
          <Input
            type="default"
            size="middle"
            name="email"
            id="email"
            value={values.email}
            status={errors?.email && 'error'}
            caption={errors?.email}
            onChange={handleChange}
          />
        </div>

        <div className="flex flex-col gap-1 mb-5 w-full">
          <label
            htmlFor="phone"
            className="text-neutral-60 tracking-tighter text-sm"
          >
            Phone Number
          </label>
          <Input
            type="default"
            size="middle"
            name="phone"
            id="phone"
            value={values.phone}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="flex flex-col gap-1 mb-5 w-1/2 pr-4">
        <label
          htmlFor="company"
          className="text-neutral-60 tracking-tighter text-sm"
        >
          Company
        </label>
        <Select
          value={values.company}
          onDropdownVisibleChange={selectOpen =>
            setCompaniesSelectOpen(selectOpen)
          }
          suffixIcon={
            <img
              src={ChevronDown}
              style={{
                transform: companiesSelectOpen ? 'rotate(180deg)' : null
              }}
              height={18}
              width={18}
              alt="Select dropdown icon"
            />
          }
          loading={!loaded}
        >
          {companies?.map(company => (
            <Select.Option key={company.id} value={company.id}>
              {company.name}
            </Select.Option>
          ))}
        </Select>
      </div>
      <h3 className="text-base font-semibold block mb-4">Address</h3>
      <div className="relative py-6 grid gap-4 -mr-4 grid-cols-4">
        <AddressFields />
      </div>
      <h3 className="text-base font-semibold block mb-4">License Info</h3>
      <div className="flex">
        <div className="flex flex-col gap-1 mb-5 w-full mr-8">
          <label
            htmlFor="applicatorLicense"
            className="text-neutral-60 tracking-tighter text-sm"
          >
            Applicator License
          </label>
          <Input
            type="default"
            size="middle"
            name="applicatorLicenseNumber"
            id="applicatorLicenseNumber"
            value={values.applicatorLicenseNumber}
            onChange={handleChange}
          />
        </div>

        <div className="flex flex-col gap-1 mb-5 w-full">
          <label
            htmlFor="applicatorLicenseExpirationDate"
            className="text-neutral-60 tracking-tighter text-sm"
          >
            Valid Through
          </label>
          <GrowUIFormField
            name="applicatorLicenseExpirationDate"
            control={DatePicker}
            dateFormat="M j, Y"
            placeholder=" "
            value={values.applicatorLicenseExpirationDate}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="my-8 relative">
        <GrowUIFormField
          value={values.notes}
          label="Notes"
          control={TextArea}
          id="notes"
          name="notes"
          className="h-32 overscroll-auto"
          onChange={handleChange}
        />
      </div>
    </>
  );
};

export default AddPerson;
