// src/screens/Property/PropertiesLanding/PropertyLandingMap/PropertyMapWrapper/PropertyMap/utilities/mapboxLayerHelpers.jsx

import React from 'react';
import { GeoJSONLayer } from 'react-mapbox-gl';
import { featureCollection } from '@turf/helpers';
import { isEmpty } from 'lodash';
import { generate } from 'shortid';

export const SELECTED_LAYER_TYPE = 'selected';
export const FOCUSED_LAYER_TYPE = 'focused';
export const CUSTOM_LAYER_TYPE = 'custom';

export const conditionFieldWithCropZones = [
  'all',
  ['==', ['get', '$landType'], 'field'],
  ['>', ['length', ['get', 'cropzones']], 0]
];
export const conditionLayerSelected = [
  '==',
  ['get', '$layer'],
  SELECTED_LAYER_TYPE
];
export const conditionLayerCustom = [
  '==',
  ['get', '$layer'],
  CUSTOM_LAYER_TYPE
];

export const buildCustomLayers = (
  geoJSONArr,
  stylesArr,
  onClickShape,
  isMultipleSelect
) => {
  return geoJSONArr.map((features, idx) => {
    if (!stylesArr[idx] || isEmpty(stylesArr[idx])) return null;

    const sortedFeatures = features.sort((a, b) => {
      if (!a.properties.sortKey) return -1;
      if (!b.properties.sortKey) return 1;
      return b.properties.sortKey - a.properties.sortKey;
    });

    const fc = featureCollection(sortedFeatures);
    const { fillColor, fillOutlineColor } = stylesArr[idx];

    const fillPaint = isMultipleSelect
      ? {
          'fill-color': [
            'case',
            conditionFieldWithCropZones,
            'rgba(20, 21, 28, 0.3)',
            ['has', 'cropColor'],
            ['get', 'cropColor'],
            fillColor
          ]
        }
      : {
          'fill-color': fillColor,
          'fill-outline-color': fillOutlineColor
        };

    const linePaint = isMultipleSelect
      ? {
          'line-color': [
            'case',
            ['has', 'cropColor'],
            'transparent',
            conditionFieldWithCropZones,
            '#14151C',
            fillOutlineColor
          ],
          'line-width': ['case', ['has', 'cropColor'], 0, 2]
        }
      : {};

    return (
      <>
        <GeoJSONLayer
          key={generate()}
          data={fc}
          type="line"
          linePaint={linePaint}
        />
        <GeoJSONLayer
          key={generate()}
          data={fc}
          type="fill"
          fillOnClick={onClickShape}
          fillPaint={{
            ...fillPaint,
            'fill-antialias': true
          }}
        />
        <GeoJSONLayer
          key={generate()}
          data={fc}
          symbolLayout={{
            'text-field': ['get', 'name'],
            'text-anchor': 'center',
            'text-justify': 'auto',
            'text-variable-anchor': ['left'],
            'text-radial-offset': 0,
            'text-size': 20,
            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
            'text-allow-overlap': false,
            'symbol-sort-key': ['get', 'sortKey']
          }}
          symbolPaint={{
            'text-color': 'white'
          }}
        />
      </>
    );
  });
};
