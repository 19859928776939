import React from 'react';
import PropTypes from 'prop-types';
import ExportPDFMapsFooter from '../../../PanelFooter/components/ExportPDFMapsFooter';
import CropColorsSection from './components/CropColorsSection';

const ExportPDFMapsOptionsForm = ({ selectedFields }) => {
  return (
    <div className="relative flex flex-col flex-grow">
      <div className="flex flex-grow overflow-y-auto">
        <CropColorsSection selectedFields={selectedFields} />
      </div>
      <ExportPDFMapsFooter step={1} selectedFields={selectedFields} />
    </div>
  );
};
ExportPDFMapsOptionsForm.propTypes = {
  selectedFields: PropTypes.arrayOf.isRequired
};

export default ExportPDFMapsOptionsForm;
