import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { Button } from '@agconnections/grow-ui';
import CropZoneIcon from 'assets/crop_zone_primary.svg';
import SelectPropertiesDrawer from './components/SelectPropertiesDrawer';

const SelectPropertiesFilter = ({ hideClearButton }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { setFieldValue, values } = useFormikContext();
  const count = values?.cropZones?.length || 0;

  const onReset = () => {
    setFieldValue('cropZones', []);
    setFieldValue('totalAcres', null);
  };

  return (
    <>
      <div className="flex justify-center gap-4">
        <Button
          ghost
          type="primary"
          className="w-full"
          onClick={() => setOpenDrawer(true)}
          icon={<img src={CropZoneIcon} alt="cropzone icon" />}
        >
          Select Properties
        </Button>
        {!hideClearButton && (
          <Button
            type="outline"
            onClick={onReset}
            className="w-full"
            disabled={!count}
          >
            Clear Selections
          </Button>
        )}
      </div>
      {openDrawer && (
        <SelectPropertiesDrawer open onClose={() => setOpenDrawer(false)} />
      )}
    </>
  );
};

SelectPropertiesFilter.defaultProps = {
  hideClearButton: false
};

SelectPropertiesFilter.propTypes = {
  hideClearButton: PropTypes.bool
};

export default SelectPropertiesFilter;
