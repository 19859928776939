import * as Yup from 'yup';
import { SHRINK_TYPE, TEMPLATE_TYPE } from 'screens/YieldV2/constants';
import {
  positiveNumberRequired,
  positiveNumberNullable
} from './validationUtils';

const commonSchema = {
  name: Yup.string()
    .required('Load Name is required')
    .max(45, 'Name characters must be less or equal than 45'),
  cropId: Yup.string().required('Crop is required'),
  sources: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().required('Source is required'),
      name: Yup.string()
    })
  ),
  destination: Yup.object().shape({
    type: Yup.string().oneOf(['STORAGE', 'SALE'], 'Invalid destination type'),
    id: Yup.string().required('Destination is required'),
    name: Yup.string()
  }),
  date: Yup.date().required('Date is required'),
  load: positiveNumberRequired('Yield Quantity'),
  loadUnit: Yup.string().required('Yield Load weight unit is required'),
  salePrice: Yup.number().when('destination.type', {
    is: 'SALE',
    then: positiveNumberRequired('Sale Price'),
    otherwise: Yup.number().nullable()
  }),
  grossRevenue: Yup.number().when('destination.type', {
    is: 'SALE',
    then: positiveNumberRequired('Gross Revenue'),
    otherwise: Yup.number().nullable()
  }),
  weightUnit: Yup.string().nullable(),
  grossWeight: positiveNumberNullable('Gross Weight'),
  tareWeight: positiveNumberNullable('Tare Weight'),
  netWeight: positiveNumberNullable('Net Weight'),
  truck: Yup.string().nullable(),
  driver: Yup.string().nullable(),
  notes: Yup.string().nullable(),
  capacity: positiveNumberNullable('Capacity')
};

const ADD_YIELD_SCHEMAS = {
  [TEMPLATE_TYPE.ALL_CROPS]: Yup.object().shape({
    ...commonSchema
  }),
  [TEMPLATE_TYPE.ROW_CROP]: Yup.object().shape({
    ...commonSchema,
    shrink: positiveNumberNullable('Shrink').test(
      'is-less-than-load',
      'Shrink should be less than Harvested Quantity',
      // eslint-disable-next-line func-names
      function(value) {
        if (!value) return true;
        const { harvestedQuantity, shrinkType } = this.parent;
        if (shrinkType === SHRINK_TYPE.BY_PERCENT) {
          return value < 100;
        }
        return value < harvestedQuantity;
      }
    ),
    shrinkType: Yup.string()
      .nullable()
      .oneOf(Object.values(SHRINK_TYPE)),
    harvestedQuantity: positiveNumberRequired('Harvested Quantity'),
    damage: positiveNumberNullable('Damage'),
    foreignMaterial: positiveNumberNullable('Foreign Material'),
    moisture: positiveNumberNullable('Moisture'),
    testWeight: positiveNumberNullable('testWeight'),
    testWeightUnit: Yup.string().nullable()
  }),
  [TEMPLATE_TYPE.SPECIALTY]: Yup.object().shape({
    ...commonSchema,
    damage: positiveNumberNullable('Damage'),
    foreignMaterial: positiveNumberNullable('Foreign Material'),
    quality: Yup.string().nullable()
  })
};

export default ADD_YIELD_SCHEMAS;
