import { getHeaderSortClassName } from 'helpers/tableSort';
import React from 'react';
import { getAbbreviatedUnit } from 'helpers/unitsAbbreviation';
import ViewIcon from 'assets/open_in_new.svg';
import infoIcon from 'assets/info.svg';
import ClickableDiv from 'components/ClickableDiv';
import { Link } from 'react-router-dom';
import { paths } from 'routes/paths';
import formatNumber from 'helpers/formatNumber';
import convertDateString from '../../helpers/dateFormat';

const dynamicTableColumns = (
  disableRender,
  selectionInfo,
  sortingInfo,
  dataInfo
) => {
  const {
    isAllInPageRecordsSelected,
    selectAllRows,
    isAllRecordsSelected
  } = selectionInfo;

  const { sortBy, sortDir } = sortingInfo;
  const { filteredAndSortedTasks, reactUiKitTablePaginationProp } = dataInfo;

  const getColSpan = (record, index) =>
    (record.banner || isAllInPageRecordsSelected) && index === 0 ? 0 : 1;
  const columns = [
    {
      title: 'Task Name',
      dataIndex: 'name',
      key: 'name',
      className: getHeaderSortClassName('name', sortBy, sortDir),
      sorter: true,
      sortOrder: sortBy === 'name' ? sortDir : null,
      onCell: (record, index) => {
        return {
          colSpan:
            (record.banner || isAllInPageRecordsSelected) && index === 0 ? 7 : 1
        };
      },
      render: (fieldValue, record) => {
        if (record.isInfoRow) {
          return (
            <div
              className="text-sm remove-parent-padding bg-blue-10 flex flex-row items-center"
              colSpan={7}
            >
              <p className="inline-flex items-center px-5 py-3">
                <img
                  alt="info"
                  src={infoIcon}
                  className="mr-2"
                  style={{ width: '24px', height: '24px' }}
                />
                {!isAllRecordsSelected
                  ? 'All records on this page are selected.'
                  : 'All records are selected'}
                {!isAllRecordsSelected && (
                  <span className="text-blue-60 font-semibold cursor-pointer ml-1">
                    <ClickableDiv
                      onClick={() =>
                        selectAllRows(filteredAndSortedTasks, 'key')
                      }
                    >
                      {` Select all ${reactUiKitTablePaginationProp.total} records`}
                    </ClickableDiv>
                  </span>
                )}
              </p>
            </div>
          );
        }
        if (record.banner) {
          return record.banner;
        }
        return <div>{fieldValue}</div>;
      }
    },
    {
      title: 'Completed Date',
      dataIndex: 'completedDate',
      key: 'completedDate',
      className: getHeaderSortClassName('completedDate', sortBy, sortDir),
      sorter: true,
      sortOrder: sortBy === 'completedDate' ? sortDir : null,
      onCell: (record, index) => ({
        colSpan: getColSpan(record, index)
      }),
      render: fieldValue => <div>{convertDateString(fieldValue)}</div>
    },
    {
      title: 'Rate',
      dataIndex: 'ratePerArea',
      key: 'ratePerArea',
      className: getHeaderSortClassName('ratePerArea', sortBy, sortDir),
      sorter: true,
      sortOrder: sortBy === 'ratePerArea' ? sortDir : null,
      onCell: (record, index) => ({
        colSpan: getColSpan(record, index)
      }),
      render: (fieldValue, record) => (
        <div>
          {`${formatNumber(
            fieldValue,
            getAbbreviatedUnit(record.ratePerAreaUnit)
          )}/${getAbbreviatedUnit(record.appliedAreaUnit)}`}
        </div>
      )
    },
    {
      title: 'Applied Quantity',
      dataIndex: 'appliedArea',
      key: 'appliedArea',
      className: getHeaderSortClassName('appliedArea', sortBy, sortDir),
      sorter: true,
      sortOrder: sortBy === 'appliedArea' ? sortDir : null,
      onCell: (record, index) => ({
        colSpan: getColSpan(record, index)
      }),
      render: (fieldValue, record) => (
        <div>{`${formatNumber(
          fieldValue,
          getAbbreviatedUnit(record.appliedAreaUnit)
        )}`}</div>
      )
    },
    {
      title: 'Unit Price',
      dataIndex: 'averagePriceAtTimeOfCreation',
      key: 'averagePriceAtTimeOfCreation',
      className: getHeaderSortClassName(
        'averagePriceAtTimeOfCreation',
        sortBy,
        sortDir
      ),
      sorter: true,
      sortOrder: sortBy === 'averagePriceAtTimeOfCreation' ? sortDir : null,
      onCell: (record, index) => ({
        colSpan: getColSpan(record, index)
      }),
      render: (fieldValue, record) => (
        <div>
          {`$${formatNumber(
            fieldValue,
            getAbbreviatedUnit(record.averagePriceUnitAtTimeOfCreation)
          )?.replace(' ', ' / ')}`}
        </div>
      )
    }
  ];
  if (!disableRender) {
    columns.push({
      title: '',
      dataIndex: 'taskId',
      key: 'taskId',
      className: getHeaderSortClassName('taskId', sortBy, sortDir),
      onCell: (record, index) => ({
        colSpan: getColSpan(record, index)
      }),
      render: (_, record) => (
        <div className="w-full flex flex-row justify-end">
          <Link
            to={`${paths.tasks}/${record.applicationId}/view`}
            className="flex flex-row"
          >
            <img className="mr-1" src={ViewIcon} alt="view icon" />
            <span>View</span>
          </Link>
        </div>
      )
    });
  }
  return columns;
};

export default dynamicTableColumns;

export const oldTableColumns = (sortBy, sortDir) => [
  {
    title: 'Task Name',
    dataIndex: 'name',
    key: 'name',
    className: getHeaderSortClassName('name', sortBy, sortDir),
    sorter: true,
    sortOrder: sortBy === 'name' ? sortDir : null,
    render: fieldValue => <div>{fieldValue}</div>
  },
  {
    title: 'Completed Date',
    dataIndex: 'completedDate',
    key: 'completedDate',
    className: getHeaderSortClassName('completedDate', sortBy, sortDir),
    sorter: true,
    sortOrder: sortBy === 'completedDate' ? sortDir : null,
    render: fieldValue => <div>{new Date(fieldValue).toLocaleDateString()}</div>
  },
  {
    title: 'Rate',
    dataIndex: 'ratePerArea',
    key: 'ratePerArea',
    className: getHeaderSortClassName('ratePerArea', sortBy, sortDir),
    sorter: true,
    sortOrder: sortBy === 'ratePerArea' ? sortDir : null,
    render: (fieldValue, record) => (
      <div>
        {`${formatNumber(
          fieldValue,
          getAbbreviatedUnit(record.ratePerAreaUnit)
        )}/${getAbbreviatedUnit(record.appliedAreaUnit)}`}
      </div>
    )
  },
  {
    title: 'Applied Area',
    dataIndex: 'appliedArea',
    key: 'appliedArea',
    className: getHeaderSortClassName('appliedArea', sortBy, sortDir),
    sorter: true,
    sortOrder: sortBy === 'appliedArea' ? sortDir : null,
    render: (fieldValue, record) => (
      <div>{`${formatNumber(
        fieldValue,
        getAbbreviatedUnit(record.appliedAreaUnit)
      )}`}</div>
    )
  },
  {
    title: 'Applied Quantity',
    dataIndex: 'totalProduct',
    key: 'totalProduct',
    className: getHeaderSortClassName('totalProduct', sortBy, sortDir),
    sorter: true,
    sortOrder: sortBy === 'totalProduct' ? sortDir : null,
    render: (fieldValue, record) => (
      <div>{`${formatNumber(
        fieldValue,
        getAbbreviatedUnit(record.totalProductUnit)
      )}`}</div>
    )
  },
  {
    title: '',
    dataIndex: 'taskId',
    key: 'taskId',
    className: getHeaderSortClassName('taskId', sortBy, sortDir),
    render: (_, record) => (
      <div className="w-full flex flex-row justify-end">
        <Link
          to={`${paths.tasks}/${record.applicationId}/view`}
          className="flex flex-row"
        >
          <img className="mr-1" src={ViewIcon} alt="view icon" />
          <span>View</span>
        </Link>
      </div>
    )
  }
];
