import React, { useState, useEffect } from 'react';
import { DatePicker, Input, Select } from '@agconnections/grow-ui';
import PropTypes from 'prop-types';
import { FieldArray, useFormikContext } from 'formik';
import PortalModal from 'components/Modals/PortalModal';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import FormattedNumber from 'components/FormattedNumber';
import {
  calculateNumberOfApplications,
  calculateProductValues
} from 'helpers/unitConversionHelpers';
import useProduct from 'hooks/useProduct';
import { getAbbreviatedUnit } from 'helpers/unitsAbbreviation';
import {
  calcTotalAcres,
  validateValue
} from 'components/ProductSideBar/helpers/productHelper';
import { timingOptions } from 'components/ProductSideBar/helpers/constants';

const initialProductValues = values => ({
  totalProductValue: values.totalProductValue || 0,
  ratePerAreaValue: values.productRateValue || 0,
  ratePerAreaUnit: values.productRateUnit || values.stdunit || '',
  ratePerTankValue: values.ratePerTankValue || 0,
  ratePerTankUnit: values.ratePerTankUnit || values.stdunit || '',
  totalProductUnit: values.totalProductUnit || values.stdpackageunit || '',
  areaUnit: 'acre',
  appliedAreaValue: values.areaValue || 0,
  coveragePercent: 100,
  appliedAreaUnit: 'acre',
  averagePriceUnitAtTimeOfCreation: '',
  averagePriceAtTimeOfCreation: values.specificCostValue || 0,
  totalCostAtTimeOfCreation: values.totalProductCost || 0,
  specificCostPerUnit: 0,
  applicationCount: values.applicationCount || 0,
  specificCostUnit: values.specificCostUnit || values.stdpackageunit,
  applicationMethod: values.applicationMethod || '',
  targetPest: values.targetPest,
  targetPestId: values.targetPestId,
  productDensity: values.productDensity || values.stdfactor || 0,
  shareOwnerInformation: {
    percentGrowerResponsibility: 100,
    totalFromGrowerInventoryQuantity: 100,
    totalFromGrowerInventoryUnit: ''
  },
  stdfactor: values.stdfactor || 1,
  stdpackageunit: values.stdpackageunit || '',
  stdunit: values.stdunit || '',
  custom: values.custom,
  targetDateEpoch: values.targetDateEpoch || 0,
  timingEvent: values.timingEvent || '',
  timingEventTag: values.timingEventTag || ''
});

const AddProductPlanModal = ({
  open,
  onClose,
  product,
  isEdit,
  rowIndex,
  memberRole,
  memberFinancialAccess
}) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();

  const { getMasterListProductUnits, getProductUnits } = useProduct();
  const [productToAdd, setProductToAdd] = useState(initialProductValues({}));

  const [stdPackageUnit, setStdPackageUnit] = useState([]);
  const [appliedAreaChanged, setAppliedAreaChanged] = useState(false);
  const [unitHeader, setUnitHeader] = useState();

  const modalType = {
    key: 'ByRatePerArea',
    value: 'ratePerAreaValue',
    unit: 'ratePerAreaUnit',
    title: 'Rate'
  };

  useEffect(() => {
    setUnitHeader(getAbbreviatedUnit(productToAdd?.specificCostUnit));
  }, [productToAdd?.specificCostUnit]);

  useEffect(() => {
    // fix a bug on add a second product on the same edit or create try
    let newProduct = initialProductValues({});

    if (product.productId) {
      newProduct = initialProductValues({
        ...product,
        totalProductUnit: product?.stdpackageunit,
        applicationMethod: '',
        totalAreaValue: values?.totalAreaValue
      });
    }

    setProductToAdd(newProduct);
  }, [product, values?.totalAreaValue]);

  useEffect(() => {
    if (open) {
      if (!product?.custom) {
        getMasterListProductUnits(product?.productId).then(setStdPackageUnit);
      } else {
        getProductUnits(product?.productId).then(setStdPackageUnit);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, isEdit, product]);

  const handleClose = () => {
    setFieldValue('timingEvent', '');
    setFieldValue('targetDate', '');
    onClose();
  };

  useEffect(() => {
    if (isEdit) {
      setFieldValue('targetDate', product?.targetDateEpoch);
    }
  }, [product, setFieldValue, isEdit]);

  const getPriceUnitLabel = unit => {
    if (unit) {
      let result = 'Price / ';
      result += unit?.toLowerCase();
      return result;
    }
    return 'Price/Unit';
  };

  const getDisabled = () => {
    if (
      memberRole === 'Full control' &&
      memberFinancialAccess === 'none' &&
      isEdit
    )
      return true;

    if (memberRole === 'Full control' && memberFinancialAccess === 'READ') {
      return true;
    }
    return false;
  };

  const showHeaderCost = () => {
    if (
      memberRole === 'Full control' &&
      memberFinancialAccess === 'none' &&
      isEdit
    ) {
      return true;
    }
    if (memberRole === 'Full control' && memberFinancialAccess === 'READ') {
      return false;
    }
    return false;
  };

  // This below needs to utilized the calc product values functions
  const calcProductValues = (
    valueChanged,
    targetValue,
    changedUnits,
    changedAppArea,
    newPrice
  ) => {
    setProductToAdd(
      calculateProductValues(
        valueChanged,
        targetValue,
        changedUnits,
        changedAppArea,
        newPrice,
        productToAdd,
        values
      )
    );
  };
  const getProductUnit = () => {
    if (
      productToAdd?.ratePerAreaUnit === 'pound' &&
      stdPackageUnit.some(obj => obj.key === 'lb')
    ) {
      return 'lb';
    }
    return productToAdd?.ratePerAreaUnit;
  };

  const productUnitChange = unit => {
    const units = {
      rateUnit:
        modalType.key === 'ByRatePerArea'
          ? unit
          : productToAdd?.ratePerAreaUnit,
      tankUnit:
        modalType.key === 'ByRatePerTank'
          ? unit
          : productToAdd?.ratePerTankUnit,
      totalProdUnit:
        modalType.key === 'ByTotalProduct'
          ? unit
          : productToAdd?.totalProductUnit
    };
    calcProductValues(modalType.key, productToAdd?.[modalType.value], units);
  };

  const calcPercentAcres = (totalAcres, changeAcres) => {
    let newAcres = 0;
    if (Number.parseFloat(totalAcres) > 0) {
      newAcres =
        (Number.parseFloat(changeAcres) / Number.parseFloat(totalAcres)) * 100;
    }

    return newAcres;
  };

  const calcValuesAcresChange = (validatedValue, propertyChanged) => {
    const areaChanged = propertyChanged === 'area';

    const newAppliedPct = areaChanged
      ? calcPercentAcres(values?.totalAreaValue, validatedValue)
      : validatedValue;

    const newAppliedArea = !areaChanged
      ? calcTotalAcres(values?.totalAreaValue, validatedValue)
      : validatedValue;

    const acresChangedValues = {
      changedPct: newAppliedPct,
      changedArea: newAppliedArea
    };

    calcProductValues(
      modalType.key,
      productToAdd?.[modalType.value],
      {},
      acresChangedValues
    );
  };

  const priceUnitChange = newPrice => {
    calcProductValues(
      modalType.key,
      productToAdd?.[modalType.value],
      {},
      {},
      newPrice
    );
  };

  const onConfirm = push => {
    setFieldTouched('planProductEntries');
    const newProduct = {
      productId: product.productId,
      density: productToAdd.productDensity,
      trackingId: product.trackingId,
      productRateValue: productToAdd.ratePerAreaValue,
      productRateUnit: productToAdd.ratePerAreaUnit,
      areaValue: productToAdd.appliedAreaValue,
      areaUnit: productToAdd.areaUnit,
      totalProductValue: productToAdd.totalProductValue,
      totalProductUnit: productToAdd.stdpackageunit,
      pricingStrategy: product.pricingStrategy,
      specificCostValue: productToAdd.averagePriceAtTimeOfCreation,
      specificCostUnit: productToAdd.specificCostUnit,
      totalProductCost: productToAdd.totalCostAtTimeOfCreation,
      coveragePercent: productToAdd.coveragePercent,
      applicationCount: productToAdd.applicationCount,
      targetDateEpoch: productToAdd.targetDateEpoch,
      timingEvent: productToAdd.timingEvent,
      timingEventTag: productToAdd.timingEventTag,
      manufacturerName: product.manufacturerName,
      productDensity: productToAdd.productDensity,
      productName: product.productName,
      shareOwnerInformation: { ...productToAdd.shareOwnerInformation },
      stdfactor: productToAdd.stdfactor,
      stdpackageunit: productToAdd.stdpackageunit,
      stdunit: productToAdd.stdunit,
      currency: product.currency,
      custom: product.custom
    };
    if (isEdit) {
      values.planProductEntries[rowIndex] = newProduct;
    } else {
      push(newProduct);
    }

    handleClose();
  };

  const canUpdate = value => value && !isNaN(value) && value > 0;

  return (
    <>
      <FieldArray name="planProductEntries">
        {({ push }) => (
          <PortalModal
            open={open}
            close={handleClose}
            confirmLabel="Save"
            onConfirm={() => onConfirm(push)}
          >
            <div className="mb-6">
              <div className="flex w-full justify-between">
                <div className="font-bold text-lg" data-testid="product-name">
                  {product?.productName}
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div
                  className="text-sm text-neutral-300"
                  data-testid="manufacture"
                >
                  {product?.manufacturerName}
                </div>
              </div>
            </div>
            <div className="flex grid-cols-2 gap-6 mb-12">
              <div className="mr-6">
                <span className="text-sm text-neutral-300">Total Product</span>
                <div>
                  <div
                    className="flex mr-6 text-xl"
                    data-testid="total-product"
                  >
                    {productToAdd?.totalProductValue % 1 === 0 ? (
                      <>
                        {productToAdd?.totalProductValue} {unitHeader}
                      </>
                    ) : (
                      <>
                        <FormattedNumber roundedTo={2}>
                          {productToAdd?.totalProductValue}
                        </FormattedNumber>{' '}
                        {productToAdd?.totalProductUnit}
                        {'s'}
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div>
                {showHeaderCost() ? null : (
                  <>
                    <span className="text-sm text-neutral-300">
                      Product Cost
                    </span>
                    <div className="text-xl ">
                      <FormattedNumber unit="usCurrency" roundedTo={2}>
                        {productToAdd?.totalCostAtTimeOfCreation}
                      </FormattedNumber>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="mt-12">
              <div className="grid grid-cols-3 gap-6 mb-12">
                <div className="my-2 mb-6">
                  <GrowUIFormField
                    control={Input}
                    label="Rate"
                    name="ratePerAreaValue"
                    value={productToAdd?.ratePerAreaValue}
                    onChange={e =>
                      calcProductValues(modalType.key, e.target.value)
                    }
                  />
                </div>
                <div className="my-2">
                  <GrowUIFormField
                    style={{ whiteSpace: 'nowrap' }}
                    control={Select}
                    label="Unit"
                    name="productUnit"
                    value={getProductUnit()}
                    items={stdPackageUnit}
                    onChange={e => {
                      productUnitChange(JSON.parse(e.target.value).key);
                    }}
                  />
                </div>
                <div className="my-2">
                  <GrowUIFormField
                    control={Input}
                    label={getPriceUnitLabel(productToAdd?.stdpackageunit)}
                    name="pricePerUnit"
                    disabled={getDisabled()}
                    value={productToAdd?.averagePriceAtTimeOfCreation}
                    onChange={e => priceUnitChange(e.target.value)}
                    maskType="usCurrency"
                  />
                </div>
                <div className="my-2 mb-6">
                  <GrowUIFormField
                    control={Input}
                    label="Applied Area"
                    name="productAppliedArea"
                    value={productToAdd?.appliedAreaValue}
                    onChange={e => {
                      if (canUpdate(e.target.value)) {
                        const validatedValue = validateValue(
                          e.target.value,
                          values?.totalAreaValue
                        );
                        calcValuesAcresChange(validatedValue, 'area');
                      }
                    }}
                    onFocus={() => setAppliedAreaChanged(true)}
                    onBlur={() => setAppliedAreaChanged(false)}
                    imask={{
                      mask: 'num \\ac',
                      lazy: false,
                      blocks: {
                        num: {
                          mask: Number,
                          radix: '.',
                          mapToRadix: [','],
                          thousandsSeparator: ','
                        }
                      }
                    }}
                  />
                </div>
                <div className="my-2">
                  <GrowUIFormField
                    control={Input}
                    label="Applied Area %"
                    name="productAppliedAreaPercent"
                    value={productToAdd?.coveragePercent}
                    onChange={e => {
                      if (canUpdate(e.target.value) && !appliedAreaChanged) {
                        const validatedValue = validateValue(
                          e.target.value,
                          100
                        );
                        calcValuesAcresChange(validatedValue, 'pct');
                      }
                    }}
                    imask={{
                      mask: 'num%',
                      lazy: false,
                      blocks: {
                        num: {
                          mask: Number,
                          radix: '.',
                          mapToRadix: [','],
                          thousandsSeparator: ','
                        }
                      }
                    }}
                  />
                </div>
                <div className="my-2">
                  <GrowUIFormField
                    control={Input}
                    label="# of Applications"
                    name="applicationCount"
                    value={productToAdd?.applicationCount}
                    onChange={e => {
                      setProductToAdd(
                        calculateNumberOfApplications(
                          productToAdd,
                          Math.trunc(e.target.value) || ''
                        )
                      );
                    }}
                    imask={{
                      mask: 'num',
                      lazy: false,
                      blocks: {
                        num: {
                          mask: Number,
                          scale: 0
                        }
                      }
                    }}
                  />
                </div>
                <div className="my-2 mb-6">
                  <GrowUIFormField
                    control={Select}
                    label="Timing"
                    name="timingEvent"
                    value={productToAdd?.timingEvent}
                    items={timingOptions}
                    data-testid="timingEvent"
                    onChange={e => {
                      const { value } = JSON.parse(e.target.value);
                      setProductToAdd({
                        ...productToAdd,
                        timingEvent: value === 'None' ? '' : value
                      });
                    }}
                  />
                </div>
                <div className="my-2">
                  <GrowUIFormField
                    control={Select}
                    label="Timing Tag"
                    name="timingEventTag"
                    value={productToAdd?.timingEventTag}
                  />
                </div>
                <div className="my-2">
                  <GrowUIFormField
                    control={DatePicker}
                    label="Target Date"
                    name="targetDate"
                    dateFormat="M d, Y"
                    onChange={e => {
                      setProductToAdd({
                        ...productToAdd,
                        targetDateEpoch: Date.parse(
                          e.target.value[0].toISOString()
                        )
                      });
                      setFieldValue('targetDate', e.target.value[0]);
                    }}
                  />
                </div>
              </div>
            </div>
          </PortalModal>
        )}
      </FieldArray>
    </>
  );
};

AddProductPlanModal.defaultProps = {
  rowIndex: null,
  product: {
    targetDateEpoch: '',
    productRateUnit: '',
    totalProductUnit: '',
    custom: false
  },
  memberRole: '',
  memberFinancialAccess: ''
};

AddProductPlanModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  rowIndex: PropTypes.string,
  product: PropTypes.shape({
    productId: PropTypes.string,
    productName: PropTypes.string,
    manufacturerName: PropTypes.string,
    targetDateEpoch: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    totalProductUnit: PropTypes.string,
    productRateUnit: PropTypes.string,
    specificCostUnit: PropTypes.string,
    density: PropTypes.number,
    productDensity: PropTypes.number,
    stdunit: PropTypes.string,
    stdpackageunit: PropTypes.string,
    pricingStrategy: PropTypes.string,
    trackingId: PropTypes.string,
    custom: PropTypes.bool,
    currency: PropTypes.string
  }),
  memberRole: PropTypes.string,
  memberFinancialAccess: PropTypes.string
};

export default AddProductPlanModal;
