import React, { useEffect, useState } from 'react';
import { useFormikContext, ErrorMessage } from 'formik';
import { Row, Col, Alert } from 'syngenta-digital-cropwise-react-ui-kit';
import './antd-override.css';
import { getAbbreviatedUnit } from 'helpers/unitsAbbreviation';
import { YIELD_DEFAULT_ROUND } from 'screens/YieldV2/constants';
import calculateGrossRevenue from '../../helpers/calculateGrossRevenue';
import calculateSalePrice from '../../helpers/calculateSalePrice';
import { isPositive } from '../../helpers/validationUtils';
import FormInputWithPrecisionDisplay from '../FormInputWithPrecisionDisplay';
import useFieldsUpdate from '../../hooks/useFieldsUpdate';

const SaleSection = () => {
  const { values } = useFormikContext();

  const [saleInfoDisabled, setSaleInfoDisabled] = useState(true);
  useEffect(() => {
    setSaleInfoDisabled(!isPositive(values.load));
  }, [values.load]);

  // Automatically update salePrice when grossRevenue or load changes
  useFieldsUpdate({
    fieldsToWatch: ['grossRevenue'],
    updateFn: calculateSalePrice,
    fieldToUpdate: 'salePrice'
  });

  // Automatically update grossRevenue when salePrice or load changes
  useFieldsUpdate({
    fieldsToWatch: ['salePrice', 'load'],
    updateFn: calculateGrossRevenue,
    fieldToUpdate: 'grossRevenue'
  });

  return (
    <div>
      {values.destination?.type === 'SALE' ? (
        <div>
          <h1 className="text-xl leading-7 font-semibold tracking-tight mb-4">
            Sale Info
          </h1>
          <Row gutter={[24, 24]} prefixCls="syt-antd-row">
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={8}
              prefixCls="syt-antd-col"
            >
              <div className="flex flex-row">
                <div className="flex flex-col gap-0.5">
                  <label
                    htmlFor="sale-price"
                    className="text-neutral-60 tracking-tighter text-sm"
                  >
                    Sale Price<span className="text-remove">*</span>
                  </label>
                  <div className="flex w-full items-center">
                    <FormInputWithPrecisionDisplay
                      fieldName="salePrice"
                      isRequired
                      isDisabled={saleInfoDisabled}
                      inputSuffix={
                        <span>per {getAbbreviatedUnit(values.loadUnit)}</span>
                      }
                      inputPrefix="$"
                      id="sale-price"
                      round={YIELD_DEFAULT_ROUND}
                    />
                  </div>
                  <ErrorMessage
                    name="salePrice"
                    component="div"
                    className="text-remove"
                  />
                  {saleInfoDisabled && (
                    <p className="text-neutral-60 tracking-tighter text-sm">
                      You must first enter a Yield quantity
                    </p>
                  )}
                </div>
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={8}
              prefixCls="syt-antd-col"
            >
              {!saleInfoDisabled && (
                <div className="flex flex-row">
                  <div className="flex flex-col gap-0.5">
                    <label
                      htmlFor="gross-revenue"
                      className="text-neutral-60 tracking-tighter text-sm"
                    >
                      Gross Revenue<span className="text-remove">*</span>
                    </label>
                    <div className="flex w-full items-center">
                      <FormInputWithPrecisionDisplay
                        fieldName="grossRevenue"
                        inputPrefix="$"
                        isRequired
                        id="gross-revenue"
                        round={YIELD_DEFAULT_ROUND}
                      />
                    </div>
                    <ErrorMessage
                      name="grossRevenue"
                      component="div"
                      className="text-remove"
                    />
                  </div>
                </div>
              )}
            </Col>
          </Row>
          <Alert
            className="yield-load-alerts mt-4"
            message="All yield delivered to a Sale Location will count towards total gross revenue."
            showIcon
            type="info"
          />
        </div>
      ) : null}
    </div>
  );
};
SaleSection.propTypes = {};
export default SaleSection;
