import { getNextYearAndPastNthYears } from 'utilities/dateHelpers';

/* eslint-disable import/prefer-default-export */
export const INTEGRATIONS = {
  all: 'All',
  mccain: 'McCain',
  johnDeere: 'johnDeere',
  simplot: 'Simplot',
  agrian: 'Agrian',
  agrianRecommendation: 'AgrianRecommendation',
  calAgPermits: 'CalAgPermits'
};

export const SUBMISSION_REVIEW_STEP = 6;
export const SUBMISSION_SIMPLOT_REVIEW_STEP = 7;
export const SUBMISSION_CALAG_REVIEW_STEP = 5;

export const INTEGRATION_INSTRUCTIONS = [
  {
    number: 1,
    header: 'Sign In',
    description: 'Authenticate with your vendor credentials'
  },
  {
    number: 2,
    header: 'Select Organization',
    description:
      'Select an organization which you would like to submit Task records'
  },
  {
    number: 3,
    header: 'Select Crop Seasons',
    description:
      'Select the Crop Seasons with which you would like to submit Task records'
  },
  {
    number: 4,
    header: 'Select Properties',
    description:
      'Select the Properties with Tasks records you would like to submit'
  },
  {
    number: 5,
    header: 'Match Properties',
    description:
      'Match the Cropwise Financials properties with the integration vendor'
  },
  {
    number: 6,
    header: 'Review and Submit',
    description:
      'Review your Task records and then submit the information to the processor'
  }
];

export const SubmissionStatus = {
  PENDING: 'pending',
  IN_PROGRESS: 'in_progress',
  DONE: 'done',
  ERROR: 'error'
};

export const AVAILABLE_SEASONS = getNextYearAndPastNthYears(8).map(year => ({
  key: year,
  value: year
}));
