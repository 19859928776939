import React, { useMemo, useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Table } from 'syngenta-digital-cropwise-react-ui-kit';
import { Modal } from 'antd';
import { CheckCircleOutlined, WarningOutlined } from '@ant-design/icons';

import useTablePaginationAndSort from 'hooks/useTablePaginationAndSort';
import { getAbbreviatedUnit } from 'helpers/unitsAbbreviation';

import useProductApplicationData from 'screens/ProductList/ProductDetails/hooks/useProductApplicationData';
import routesForPPLToCreate from 'screens/ProductList/helpers/creationRoutes';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useTaskPriceList, {
  bodyConstructor
} from 'screens/ProductList/ProductDetails/hooks/useTaskPriceList';
import { Spinner } from '@agconnections/grow-ui';
import featureFlagRenderItem from 'helpers/featureFlagRenderItem';
import { Context } from 'components/Store';
import formatNumber from 'helpers/formatNumber';
import ProductDetailTableEmptyState from '../ProductDetailTableEmptyState';
import TaskProductTable from './components/TaskProductTable';
import { ProductDetailsContext } from '../../context/ProductDetailsProvider';
import showTableTotal from './components/PaginationFooter';
import dynamicTableColumns, {
  oldTableColumns
} from './components/TableColumns';
import getFilteredAndSortedTasks from './helpers/filterAndSort';
import submissionBannerStatus from './components/SubmissionBannerStatus';
import calculateTimeEstimate from '../../helpers/calculateTimeEstimate';
import './index.css';

const PAGE_SIZE = 20;

const ProductDetailTasksTable = ({
  cropSeasons,
  dateRange,
  productDetails
}) => {
  const {
    listPrice,
    listPriceUnit,
    productName,
    stdPackageUnit,
    stdUnit
  } = productDetails;
  const [totalProduct, setTotalProduct] = useState('');

  const {
    isUpdateListPrice,
    setIsUpdateListPrice,
    selectedTaskIds,
    setSelectedTaskIds,
    resetValues,
    showInfoRow,
    selectAllRows
  } = useContext(ProductDetailsContext);
  const {
    releaseFeInventoryTaskListPriceUpdate,
    releaseFeInventoryTaskListPriceUpdateByOrg
  } = useFlags();
  const [{ organization }] = useContext(Context);
  const { id: productId } = useParams();
  const history = useHistory();
  const rowKey = 'key';
  const {
    getProductApplications,
    tasks,
    isLoading
  } = useProductApplicationData(productId);
  const totalSelectedRows = selectedTaskIds.length;

  const {
    submit,
    loadingSubmit,
    statusSubmit,
    submissionsStatus,
    getSubmissionsStatus,
    dismissSubmissionsStatus,
    loadingRetrySubmission,
    retrySubmission
  } = useTaskPriceList(productId);

  const { confirm } = Modal;

  const showConfirmExit = () => {
    confirm({
      title: 'Cancel price update?',
      icon: <WarningOutlined />,
      content: 'The selected records will not be updated with the list price.',
      okText: 'Cancel update',
      cancelText: 'Continue selecting',
      okButtonProps: {
        style: {
          backgroundColor: '#14803C'
        }
      },
      onOk() {
        resetValues();
      }
    });
  };

  const showConfirmUpdate = async () => {
    const timeEstimate = await calculateTimeEstimate(totalSelectedRows);
    Modal.confirm({
      title: 'Update records with List Price?',
      icon: <CheckCircleOutlined style={{ color: '#14803C' }} />,
      content: `The price of ${productName} on the selected records will be updated to $${formatNumber(
        listPrice
      )} / ${getAbbreviatedUnit(listPriceUnit)} within ${timeEstimate}.`,
      okText: 'Update records',
      okButtonProps: {
        style: {
          backgroundColor: '#14803C'
        }
      },
      onOk() {
        submit(
          bodyConstructor(
            tasks.length === totalSelectedRows,
            listPrice,
            selectedTaskIds
          ),
          resetValues
        );
      }
    });
  };

  const isFeatureFlagEnabled = featureFlagRenderItem(
    releaseFeInventoryTaskListPriceUpdate,
    releaseFeInventoryTaskListPriceUpdateByOrg,
    organization?.id
  );

  const {
    reactUiKitTableOnChange,
    reactUiKitTablePaginationProp,
    sortBy,
    sortDir,
    page,
    setPage,
    setTotalItemsCount
  } = useTablePaginationAndSort({
    defaultSortBy: 'name',
    defaultSortDir: 'ascend',
    pageSize: PAGE_SIZE,
    pagination: {
      showTotal: (total, range) => {
        const selectedRowsInfo = {
          totalSelectedRows,
          selectedTaskIds
        };
        const actions = {
          listPriceAction: setIsUpdateListPrice,
          showConfirm: showConfirmExit,
          showConfirmUpdate,
          resetValues
        };
        const displayOptions = {
          isUpdateListPrice,
          hasListPrice: listPrice && listPrice !== 'None'
        };
        return showTableTotal(
          totalProduct,
          range,
          total,
          selectedRowsInfo,
          actions,
          displayOptions,
          isFeatureFlagEnabled
        );
      }
    }
  });

  const isAllRecordsSelected =
    totalSelectedRows === reactUiKitTablePaginationProp.total;

  const mappedTasks = useMemo(
    () =>
      tasks.map((task, index) => ({
        id: task.applicationId,
        key: `${task.applicationId}_${task.trackingId}_${index}`,
        name: task.applicationName,
        applicationId: task.applicationId,
        completedDate: new Date(task.completedDateTime),
        taskCropSeasons: task.cropSeasonIds,
        ratePerArea: task.ratePerAreaValue,
        ratePerAreaUnit: task.ratePerAreaUnit,
        appliedArea: task.appliedAreaValue,
        appliedAreaUnit: task.appliedAreaUnit,
        totalProduct: task.productQuantityValue,
        totalProductUnit: task.productQuantityUnit,
        cropSeasonIds: task.cropSeasonIds,
        averagePriceAtTimeOfCreation: task.averagePriceAtTimeOfCreation,
        averagePriceUnitAtTimeOfCreation: getAbbreviatedUnit(
          stdPackageUnit ?? stdUnit
        )
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tasks]
  );

  const filteredAndSortedTasks = useMemo(() => {
    return getFilteredAndSortedTasks(
      mappedTasks,
      sortBy,
      sortDir,
      cropSeasons,
      dateRange
    );
  }, [mappedTasks, sortBy, sortDir, cropSeasons, dateRange]);

  useEffect(() => {
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cropSeasons, dateRange]);

  useEffect(() => {
    const total = filteredAndSortedTasks.reduce((acc, task) => {
      return acc + task.totalProduct;
    }, 0);

    setTotalProduct(
      `${total.toFixed(2)} ${getAbbreviatedUnit(
        filteredAndSortedTasks[0]?.totalProductUnit
      ) ?? ''}`
    );
  }, [filteredAndSortedTasks]);

  const paginatedData = useMemo(() => {
    return filteredAndSortedTasks.slice(
      (page - 1) * PAGE_SIZE,
      page * PAGE_SIZE
    );
  }, [filteredAndSortedTasks, page]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(
    () => {
      const selectionInfo = {
        isAllInPageRecordsSelected: showInfoRow,
        selectAllRows,
        isAllRecordsSelected
      };
      const sortingInfo = {
        sortBy,
        sortDir
      };
      const dataInfo = {
        filteredAndSortedTasks,
        reactUiKitTablePaginationProp
      };
      return dynamicTableColumns(
        isUpdateListPrice,
        selectionInfo,
        sortingInfo,
        dataInfo
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isUpdateListPrice,
      isAllRecordsSelected,
      sortBy,
      sortDir,
      filteredAndSortedTasks,
      reactUiKitTablePaginationProp,
      showInfoRow
    ]
  );

  useEffect(() => {
    if (statusSubmit === 'success') {
      resetValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusSubmit]);

  useEffect(() => {
    getProductApplications();
    getSubmissionsStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  useEffect(() => {
    setTotalItemsCount(filteredAndSortedTasks.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredAndSortedTasks]);

  const isEmpty = paginatedData.length === 0;
  useEffect(() => {
    const filteredIds = filteredAndSortedTasks.map(item => item[rowKey]);
    const newSelectedTaskIds = selectedTaskIds.filter(id =>
      filteredIds.includes(id)
    );
    if (newSelectedTaskIds.length !== selectedTaskIds.length) {
      setSelectedTaskIds(newSelectedTaskIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredAndSortedTasks, selectedTaskIds, rowKey]);

  return (
    <div className="flex" style={{ height: 'calc(100% - 3rem)' }}>
      {loadingSubmit && (
        <div className="h-full w-full flex flex-col justify-center items-center">
          <div>
            <Spinner size="lg" />
          </div>
          <span className="mt-4 text-base font-semibold">
            Submitting request...
          </span>
        </div>
      )}
      {!isLoading && isEmpty && (
        <ProductDetailTableEmptyState
          type="completed_tasks"
          totalItemCount={mappedTasks.length}
          onButtonClick={() => history.push(routesForPPLToCreate.task)}
        />
      )}
      <div
        className={`${
          isFeatureFlagEnabled ? 'flex-shrink' : 'flex-1 overflow-scroll'
        } max-w-full`}
      >
        {!loadingSubmit && (
          <>
            {!isEmpty &&
              (!isFeatureFlagEnabled ? (
                <Table
                  data-testid="loads-table"
                  rowKey="id"
                  loading={isLoading}
                  columns={oldTableColumns(sortBy, sortDir)}
                  dataSource={[...paginatedData]}
                  pagination={reactUiKitTablePaginationProp}
                  onChange={reactUiKitTableOnChange}
                  tableLayout="auto"
                />
              ) : (
                <TaskProductTable
                  data-testid="loads-table"
                  rowKey="key"
                  columns={columns}
                  loading={isLoading}
                  banner={submissionBannerStatus(
                    submissionsStatus,
                    dismissSubmissionsStatus,
                    listPriceUnit,
                    loadingRetrySubmission,
                    retrySubmission,
                    isUpdateListPrice
                  )}
                  dataSource={paginatedData}
                  fullDataSource={filteredAndSortedTasks}
                  pagination={reactUiKitTablePaginationProp}
                  onChange={reactUiKitTableOnChange}
                  tableLayout="fix"
                />
              ))}
          </>
        )}
      </div>
    </div>
  );
};

ProductDetailTasksTable.defaultProps = {
  dateRange: null
};

ProductDetailTasksTable.propTypes = {
  cropSeasons: PropTypes.arrayOf(PropTypes.string).isRequired,
  dateRange: PropTypes.arrayOf(PropTypes.any),
  productDetails: PropTypes.shape({
    listPrice: PropTypes.number,
    listPriceUnit: PropTypes.string,
    productName: PropTypes.string,
    stdPackageUnit: PropTypes.string,
    stdUnit: PropTypes.string
  }).isRequired
};

export default ProductDetailTasksTable;
