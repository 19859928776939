import React from 'react';
import PropTypes from 'prop-types';
import { getAbbreviatedUnit } from 'helpers/unitsAbbreviation';
import { APPLICATION_OPTIONS } from 'components/ProductSideBar/helpers/constants';

const ApplicationOptionField = ({
  applicationOption,
  product,
  className = ''
}) => {
  return (
    <div className={`flex flex-col ${className}`}>
      <span className="text-sm text-neutral-300 mb-2">
        {applicationOption.title}
      </span>
      <div className="flex flex-row items-center text-xl">
        <span className="pr-2" data-testid="total-product">
          {`${Number(product?.[applicationOption.value]).toFixed(2)} `}
          {getAbbreviatedUnit(product[applicationOption.unit])}
        </span>
      </div>
    </div>
  );
};

ApplicationOptionField.defaultProps = {
  className: ''
};

ApplicationOptionField.propTypes = {
  applicationOption: PropTypes.oneOf(Object.values(APPLICATION_OPTIONS))
    .isRequired,
  product: PropTypes.shape({
    ratePerAreaValue: PropTypes.number,
    totalProductValue: PropTypes.number,
    ratePerTankValue: PropTypes.number,
    ratePerAreaUnit: PropTypes.string,
    totalProductUnit: PropTypes.string,
    ratePerTankUnit: PropTypes.string
  }).isRequired,
  className: PropTypes.string
};

export default ApplicationOptionField;
