import EditOutlinedIcon from 'components/Icons/EditOutlinedIcon';
import React from 'react';
import { Button, ButtonType } from 'syngenta-digital-cropwise-react-ui-kit';

const showTableTotal = (
  totalAppliedQuantity,
  range,
  totalRows,
  selectedRowsInfo,
  actions,
  displayOptions,
  isFeatureFlagEnabled
) => {
  const { totalSelectedRows, selectedTaskIds } = selectedRowsInfo;
  const {
    listPriceAction,
    showConfirm,
    showConfirmUpdate,
    resetValues
  } = actions;
  const { isUpdateListPrice, hasListPrice } = displayOptions;
  const renderPriceListButton =
    isFeatureFlagEnabled && !isUpdateListPrice && hasListPrice;

  return (
    <div className="flex items-center w-full">
      <div
        style={{
          bottom: -2
        }}
        className="flex flex-row justify-between items-center w-full"
      >
        <div
          className="w-auto absolute"
          style={{ left: renderPriceListButton ? 20 : 0 }}
        >
          {!!renderPriceListButton && (
            <Button
              className="text-green-700"
              type={ButtonType.primary}
              onClick={() => listPriceAction(prev => !prev)}
              ghost
            >
              <EditOutlinedIcon color="#14803C" />
              Update Records with List Price
            </Button>
          )}
          {isUpdateListPrice && hasListPrice && (
            <div className="flex flex-row">
              <p className="mr-2 ml-4 text-blue-60 font-semibold">{`${totalSelectedRows} selected`}</p>
              <Button
                type={ButtonType.primary}
                className="ml-2"
                disabled={!selectedTaskIds.length}
                onClick={showConfirmUpdate}
              >
                Update Records
              </Button>
              <Button
                className="mx-4"
                type={ButtonType.outline}
                onClick={() => {
                  if (selectedTaskIds.length > 0) {
                    showConfirm();
                    return;
                  }
                  resetValues();
                }}
              >
                Cancel
              </Button>
            </div>
          )}
        </div>
        <div className="flex flex-row flex-end">
          <div
            className="bg-banner-alert-blue-10 rounded py-0.5 px-3 relative"
            data-testid="product-detail-total-value"
          >
            <span>Total Applied Quantity: </span>
            <span className="font-semibold">{totalAppliedQuantity}</span>
          </div>
          <span className="ml-4 text-neutral-60">
            {range?.[0]}-{range?.[1]} of {totalRows}
          </span>
        </div>
      </div>
    </div>
  );
};

export default showTableTotal;
