import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import useCropData from 'hooks/useCropData';
import { SimpleModal, Search } from '@agconnections/grow-ui';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import useCropSeason from 'hooks/useCropSeason';
import { Context } from 'components/Store';
import { MASSIVE_CHANGE_SUMMARY } from 'reducers/reducer';
import SeasonCreateModal from 'components/Modals/SeasonCreateModal';
import submitValidationChecker from 'screens/Property/PropertiesLanding/helpers/submitValidationChecker';
import { getCropZonesOnly } from 'screens/Property/PropertiesLanding/helpers/massAssigner';

import cropZoneModalSchema from './schema';

const buildSearchItems = items =>
  items?.map(item => ({
    key: item.id,
    value: item.name
  })) || [];

const DEFAULT_CROP_SEASON = '88888888-8888-8888-8888-888888888888';

const AddCropZonesModal = ({ open, onClose }) => {
  const [
    { fieldsToMassiveAssign, massiveChangeSummary },
    dispatch
  ] = useContext(Context);
  const { splitCropSeasons, dividedCropSeasons } = useCropSeason();
  const { crops } = useCropData();

  const [cropSeasonSelected, setCropSeasonSelected] = useState('');
  const [openCropSeasonNewModal, setOpenCropSeasonNewModal] = useState(false);

  const cropItems = buildSearchItems(crops);

  const activeSeasons = JSON.parse(localStorage.getItem('selectedCropSeasons'));

  const mapCropSeasons = () => {
    const { active, past, upcoming } = dividedCropSeasons;
    let seasonsActive = [];
    let seasonsPast = [];
    let seasonsUpcoming = [];
    if (active?.length > 0) {
      seasonsActive = buildSearchItems(active)?.filter(season =>
        activeSeasons.includes(season.key)
      );
    }
    if (past?.length > 0) {
      seasonsPast = buildSearchItems(past)?.filter(season =>
        activeSeasons.includes(season.key)
      );
    }
    if (upcoming?.length > 0) {
      seasonsUpcoming = buildSearchItems(upcoming)?.filter(season =>
        activeSeasons.includes(season.key)
      );
    }

    if (seasonsActive?.length > 0) {
      return seasonsActive;
    }
    if (seasonsPast?.length > 0) {
      return seasonsPast;
    }
    if (seasonsUpcoming?.length > 0) {
      return seasonsUpcoming;
    }
    return [];
  };

  const cropSeasonsItems = useMemo(() => {
    return !activeSeasons.includes(DEFAULT_CROP_SEASON) &&
      (dividedCropSeasons.active.length ||
        dividedCropSeasons.past.length ||
        dividedCropSeasons.upcoming.length)
      ? mapCropSeasons()
      : [{ key: '', value: '+ Create New Crop Season' }];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dividedCropSeasons]);

  const onConfirmHandler = async values => {
    dispatch({
      type: MASSIVE_CHANGE_SUMMARY,
      payload: {
        ...massiveChangeSummary,
        ...values,
        fieldsToMassiveAssign:
          massiveChangeSummary.action === 'Changed'
            ? getCropZonesOnly(fieldsToMassiveAssign)
            : fieldsToMassiveAssign
      }
    });
    onClose();
  };

  const onSelectCropSeason = (e, setFieldValue) => {
    if (!e.key) {
      setOpenCropSeasonNewModal(true);
    } else {
      setFieldValue('season.name', e.value);
      setFieldValue('season.id', e.key);
    }
  };

  useEffect(() => {
    splitCropSeasons();
  }, [splitCropSeasons]);

  useEffect(() => {
    if (activeSeasons.length === 1) {
      setCropSeasonSelected(activeSeasons[0]);
    }
  }, [activeSeasons]);

  const isCropSeasonSelectDisabled = useMemo(() => {
    return (
      !activeSeasons.includes(DEFAULT_CROP_SEASON) &&
      activeSeasons?.length === 1 &&
      (dividedCropSeasons.active.length > 0 ||
        dividedCropSeasons.past.length > 0 ||
        dividedCropSeasons.upcoming.length > 0)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dividedCropSeasons]);

  const actionType = massiveChangeSummary.action === 'Added' ? 'Add' : 'Change';

  return (
    <>
      <Formik
        initialValues={{
          season: {
            id: cropSeasonSelected,
            name: cropSeasonsItems.find(
              cropSeason => cropSeason.key === cropSeasonSelected
            )?.value
          },
          crop: {
            id: '',
            name: ''
          }
        }}
        validationSchema={cropZoneModalSchema(actionType)}
        onSubmit={onConfirmHandler}
        enableReinitialize
      >
        {({ submitForm, setFieldValue, isSubmitting, resetForm, values }) => (
          <SimpleModal
            type="basic"
            open={open}
            close={() => {
              resetForm();
              onClose();
            }}
            confirmLabel="Save"
            isSaving={isSubmitting}
            onConfirm={() => {
              submitValidationChecker(actionType, values, submitForm);
            }}
          >
            <h1 className="text-base font-bold">{actionType} Crop Zone(s)</h1>

            <div className="flex-col mt-3">
              {actionType === 'Add' && (
                <div className="mb-4">
                  <label
                    htmlFor="season"
                    className="text-neutral-600 text-sm inline-block"
                  >
                    Crop Season*
                  </label>
                  <GrowUIFormField
                    control={Search}
                    name="season"
                    items={cropSeasonsItems}
                    placeholder="Select Crop Season"
                    onSelect={e => onSelectCropSeason(e, setFieldValue)}
                    disabled={isCropSeasonSelectDisabled}
                    selectedValue={values.season?.id}
                  />
                </div>
              )}
              <div>
                <label
                  htmlFor="crop"
                  className="text-neutral-600 text-sm inline-block"
                >
                  Crop*
                </label>
                <GrowUIFormField
                  control={Search}
                  name="crop"
                  items={cropItems}
                  placeholder="Select Crop"
                  onSelect={e => {
                    setFieldValue('crop.id', e.key);
                    setFieldValue('crop.name', e.value);
                  }}
                  selectedValue={values.crop?.id}
                />
              </div>
            </div>
          </SimpleModal>
        )}
      </Formik>
      {openCropSeasonNewModal && (
        <SeasonCreateModal
          open={openCropSeasonNewModal}
          onClose={() => setOpenCropSeasonNewModal(false)}
        />
      )}
    </>
  );
};

AddCropZonesModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default AddCropZonesModal;
