import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import {
  SimpleModal,
  Input,
  DatePicker,
  Form,
  Toast
} from '@agconnections/grow-ui';
import { Formik } from 'formik';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import { season as seasonApi } from 'utilities/api';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import { Context } from 'components/Store';
import { selectCropSeason } from 'utilities/updated-permissions';

const SeasonCreateModal = ({ open, onClose, onSuccess }) => {
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [, dispatch] = useContext(Context);
  const initialValues = {
    name: '',
    date: null
  };

  const onConfirmHandler = async values => {
    const startDateEpoch = new Date(values.date[0]).getTime();
    const endDateEpoch = new Date(values.date[1]).getTime();
    const body = {
      name: values.name,
      startDateEpoch,
      endDateEpoch
    };
    setSaving(true);
    const { promise } = seasonApi.create(body);
    await promise
      .then(({ data }) => {
        onSuccess(data);
        setSaving(false);
        selectCropSeason(dispatch, [data.id]);
        onClose();
      })
      .catch(catchCancel)
      .catch(err => {
        setSaving(false);
        if (err.response?.data?.displayErrorMessage) {
          if (err.response?.data?.code === 'ESEA017') {
            setError(
              'Field Name : Crop Zone Name already exists in a Crop Season within the selected dates. Please remove selection in order to save this Crop Season.'
            );
          } else {
            setError(err.response.data.displayErrorMessage);
          }
        } else {
          parseServerError(dispatch)(err);
        }
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      // validationSchema={schema}
      onSubmit={values => {
        onConfirmHandler(values);
        // amplitude trigger
      }}
    >
      {({ values, handleSubmit }) => (
        <SimpleModal
          open={open}
          close={onClose}
          confirmLabel="Save"
          isSaving={saving}
          onConfirm={() => handleSubmit(values)}
        >
          {error && (
            <Toast icon="error" onClose={() => setError('')}>
              {error}
            </Toast>
          )}
          <h1 className="text-xl font-bold">Create New Crop Season</h1>

          <div className="mt-10 py-2">
            <div className="grid mb-4 mr-12">
              <Form.Field className="my-2 mb-6">
                <GrowUIFormField
                  control={Input}
                  label="Crop Season Name *"
                  name="name"
                />
              </Form.Field>
              <div>&nbsp;</div>
              <Form.Field className="my-2">
                <GrowUIFormField
                  label="Target Dates *"
                  control={DatePicker}
                  name="date"
                  isRangePicker
                  data-testid="cropSeasonDates"
                />
              </Form.Field>
            </div>
          </div>
        </SimpleModal>
      )}
    </Formik>
  );
};

SeasonCreateModal.defaultProps = {
  onSuccess: () => {}
};

SeasonCreateModal.propTypes = {
  onSuccess: PropTypes.func,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default SeasonCreateModal;
